import { HelmetProvider, Helmet } from "react-helmet-async";
import { useIntl } from 'react-intl';
import { useParams, useNavigate } from "react-router";
import React, { useEffect, useState } from "react";

import ym from 'react-yandex-metrika';

import Header from '../components/Header';
import Footer from '../components/Footer';
import GetFlightsData from "../components/GetFlightsData";              // Запрос результатов поиска
import GetAirportsData from "../components/GetAirportsData";            // Запрос Аэропортов для установления необходимых для отображения данных
import RenderSearchResults from "../components/RenderSearchResults";    // Обработчик рендера поисковой выдачи в режиме поиска и предложений в режиме информации о рейсе
import FlightInfo from "../components/FlightInfo";                      // Блок с информацией о рейсе
import GetAiportById from "../components/GetAiportById";                // Получение данных аэропорта по ID
import SearchFilters from "../components/SearchFilters";                // Строка с фильтрами
import SearchStatusBar from "../components/SearchStatusBar";            // Строка со статусом
import DataPicker, { SetDataPickerOffset } from "../components/DataPicker";  // Строка с фильтром выбора дат, предустановка отсупов в селекторе дат
import GetFirstRequestDate from "../components/GetFirstRequestDate";    // Определение даты запроса, где изначально не указано время
import GetAverageFlightTime from "../components/GetAverageFlightTime";
import UnderHeaderLine from "../components/UnderHeaderLine";
import dateWithoutTimeZone from "../components/DateWithoutTimeZone";
import isoDateWithoutTimeZone from "../components/IsoDateWithoutTimeZone";

export default function Search( props ) {

    let { fromAirport, toAirport, date, passengers } = useParams();                                   // Входящие данные в URL

    const [flightsData, setFlightsData] = useState(null);                 // Данные результатов запросов
    const [airportsData, setAirportsData] = useState(null);
    const [flightInfo, setFlightInfo] = useState(null);

    const [emptyLeg, setEmptyLeg] = useState(props.emptyLeg);

    const [airportFromData, setAirportFromData] = useState(null);         // Параметры запроса
    const [airportToData, setAirportToData] = useState(null);
    const [pax, setPax] = useState(passengers ? parseInt(passengers) : null);
    const [departureDate, setDepartureDate] = useState(date ? (new Date(date)) : GetFirstRequestDate(new Date));
    const [departureBackDate, setDepartureBackDate] = useState(null);

    const [dataPickerOffset, setDataPickerOffset] = useState(false);

    const [currency, setCurrency] = useState('rub');                      // Вылюта по умолчанию, еще доступны: usd, eur

    const [lastRequest, setLastRequest] = useState(null);                 // Системные переменные для запросов
    const [newRequest, setNewRequest] = useState(null);
    const [isLoading, setIsLoading] = useState( true);
    const [error, setError] = useState(null);

    const [whichDateChoosing, setWhichDateChoosing] = useState(null); // Состояние окна выбора дат десктопа: null - закрыто, there - выбор даты вылета туда, back - выбор даты вылета обратно

    let { flightId } = useParams();
    let { requestId } = useParams();

    let flightsNumber;
    let averageFlightTime;

    const Intl = useIntl();

    let navigate = useNavigate();
    let pageTitle;

    // console.log( 'Адрес локали: ' + Intl.messages['currentLocaleLink'] );
    // console.log( 'Проход через модуль search' );
    // console.log( 'ID поискового запроса в URL: ' +  requestId );
    // console.log( 'ID рейса запроса в URL: ' +  flightId );
    // console.log( 'Установлена в "search" дата вылета: ' + departureDate );
    // console.log( 'Данные переданного аэропорта вылета: ' + fromAirport );
    // console.log( 'Данные переданного аэропорта прилета: ' + toAirport );

    // Обработка первого захода
    useEffect(() => {
        // Заход по прямой ссылке с ID результатов поиска
        if ( requestId ) {
            GetFlightsData( { request_id : requestId }, setFlightsData, setFlightInfo, setIsLoading, setError );
            // console.log('Инициализация с ID поискового запроса в URL: ' + requestId );
        }
        // Заход с главной страницы с указанным направлением
        if ( toAirport && fromAirport ) {
            setBaseSearchProps(
                isoDateWithoutTimeZone( departureDate ),
                departureBackDate,
                (pax || 1),
                fromAirport,
                toAirport,
                true,
                false
            );
        }
        // Заход по прямой ссылке с ID конкретного предложения
        if ( flightId ) {
            GetFlightsData( { card_id : flightId }, setFlightsData, setFlightInfo, setIsLoading, setError );
        }
        // EmptyLegs
        if ( emptyLeg ) {
            GetFlightsData( { emptyLeg : true, pax_there: 1 }, setFlightsData, setFlightInfo, setIsLoading, setError );
        }
        GetAirportsData( setAirportsData );
        // console.log('Инициализаия закончена');
    }, [])

    // Обновление данных при изменении запроса
    useEffect(() => {
        if ( !isLoading && newRequest ) {
            setLastRequest( newRequest );
            setIsLoading( true);
            GetFlightsData(newRequest, setFlightsData, setFlightInfo,setIsLoading, setError );
            setNewRequest( null );
            // console.log ('Автоматический запрос: ' + newRequest);
        }
    }, [newRequest, isLoading])

    // Получение данных аэропортов вылета и назначения, после получения данных по всем аэропортам
    useEffect(() => {
        if ( airportsData && airportFromData && airportToData && !airportFromData.icao && !airportToData.icao ) {
            setAirportFromData(GetAiportById(airportFromData, airportsData, Intl.locale));
            setAirportToData(GetAiportById(airportToData, airportsData, Intl.locale));
        }
    }, [airportsData, airportToData, airportFromData])

    // Установка сдвига в окне выбора дат, при его открытии в десктопе
    useEffect(() => {
        if (whichDateChoosing === 'there') {
            SetDataPickerOffset({
                departureDate: departureDate,
                desktop: true,
            });
        }
        if (whichDateChoosing === 'back') {
            SetDataPickerOffset({
                departureBackDate: departureBackDate,
                desktop: true,
            });
        }
    }, [whichDateChoosing])

    // Обработка изменения параметров, формирование нового запроса
    useEffect(() => {
        if ( lastRequest )
        {
            let request = Object.assign({},lastRequest);
            let requestChanged = false;
            if ( lastRequest.pax_there !== pax ) { request.pax_there = pax; requestChanged = true;}
            if ( lastRequest.departure_date_there !== departureDate.toISOString() ) { request.departure_date_there = departureDate.toISOString(); requestChanged = true; }
            if ( lastRequest?.departure_date_back !== departureBackDate?.toISOString() ) {
                if (departureBackDate) {
                    request.departure_date_back = departureBackDate.toISOString();
                } else {
                    delete request.departure_date_back;
                    delete request.pax_back;
                }
                requestChanged = true;
            }
            if ( departureBackDate ) { request.pax_back = pax }
            // Устанавливаем отступы для временной шкалы в мобильной версии
            if ( dataPickerOffset &&
                (departureDate.toDateString() !== GetFirstRequestDate(new Date).toDateString()
                    || departureDate.getHours() !== GetFirstRequestDate(new Date).getHours()
                    || departureBackDate
                )
            ) {
                SetDataPickerOffset({
                    departureDate: departureDate,
                    departureBackDate: departureBackDate,
                });
                setDataPickerOffset(false); // Установили флаг, что начальные отступы установлены
            }
            if ( requestChanged ) { setNewRequest( request ); } else { setNewRequest( null ) }
            // console.log ('Сформирован автоматический запрос');
        }
    }, [pax, departureDate, departureBackDate])

    // Обновление URL страницы поиска после получения результатов поиска, чтобы ее потом можно было открыть по-прямому URL
    // Если заход на страницу поиска произошел по ID запроса, устанавливаем критерии запроса
    // Если параметры рейса изменены на странице информации о рейсе, после получения поисковой выдачи обновляем страницу, если нет данных кидаем в результаты поиска
    useEffect(() => {
        if ( flightsData && !flightId && pax && flightsData.cards.length > 0 ) {
            let requestURL = Intl.messages[ 'currentLocaleLink' ] + 'search/' + flightsData.id;
            navigate( requestURL , { replace: true });
            // console.log('Пришли данные по рейсам, URL установлен');
        }
        if ( flightsData && !flightId && !pax && !emptyLeg) {
                setBaseSearchProps(
                    flightsData.query.departure_date_there,
                    flightsData.query.departure_date_back,
                    flightsData.query.pax_there,
                    flightsData.query.departure_airport,
                    flightsData.query.arrival_airport,
                    false,
                    true
                );
                // console.log( 'Изначальная дата: ' + departureDate + ', полученная в запросе дата: ' + new Date(flightsData.query.departure_date_there) );
            }
        if ( flightsData && flightInfo && pax && departureDate ) {
            if (
                ( flightsData.cards[0]?.routes[0].departure_date !== flightInfo?.routes[0].departure_date ) ||
                ( flightsData.cards[0]?.routes[0].pax !== flightInfo?.routes[0].pax ) ||
                ( flightsData.cards[0]?.routes[1]?.departure_date !== flightInfo?.routes[1]?.departure_date )
            ) {
                let newFlightInfoFound;
                flightsData.cards.forEach(item => {
                    if (
                        ( flightInfo?.routes[0].aircraft === item?.routes[0].aircraft )
                    ) {
                        // console.log('Найдено ВС на полет с изменной датой или количеством пассажиров: ' + item?.routes[0].aircraft);
                        if (!newFlightInfoFound) { setFlightInfo(item) }
                        newFlightInfoFound = item;
                    }
                })
                if ( newFlightInfoFound ) {
                    let requestURL = Intl.messages[ 'currentLocaleLink' ] + 'flight/' + newFlightInfoFound.id;
                    navigate( requestURL , { replace: true });
                } else {
                    let requestURL = Intl.messages[ 'currentLocaleLink' ] + 'search/' + flightsData.id;
                    setFlightInfo(null);
                    navigate( requestURL , { replace: true });
                }
            }
        }
    }, [flightsData])

    // Обработка изменения информации о конкретном рейсе
    useEffect(() => {
        // Обработка случая, когда переход на свойства рейса произошел по прямой ссылке, получены данные рейса, т.е. есть id, получаем альтернативные предложения
        if (  flightInfo && !flightsData && isLoading ) {
            let departure_date_there;
            let departure_airport;
            let arrival_airport;
            // Обработка emptyLegs, в этом случае, при вызове отсутствует информация в query
            if (flightInfo.query.departure_date_there) {
                departure_date_there = flightInfo.query.departure_date_there;
                departure_airport = flightInfo.query.departure_airport;
                arrival_airport = flightInfo.query.arrival_airport;
            } else {
                departure_date_there = flightInfo?.routes[0].departure_date_local;
                departure_airport = flightInfo?.routes[0].departure_airport;
                arrival_airport = flightInfo?.routes[0].arrival_airport;
            }
            setBaseSearchProps(
                departure_date_there,
                flightInfo.query.departure_date_back,
                flightInfo.query.pax_there,
                departure_airport,
                arrival_airport,
                true,
                true
            );
        }
        if ( flightInfo && emptyLeg ) {
            setEmptyLeg(false);
            setBaseSearchProps(
                flightInfo?.routes[0].departure_date_local,
                flightInfo?.routes[1]?.departure_date_local,
                1,
                flightInfo?.routes[0].departure_airport,
                flightInfo?.routes[0].arrival_airport,
                true,
                true
            );
        }
    }, [flightInfo])

    // Установка базовых параметров поиска, в случае прихода пользователя по прямой ссылке, и после уточнения параметров запросом к серверу
    function setBaseSearchProps( departure_date_there, departure_date_back, pax_there, departure_airport, arrival_airport, make_request, make_offset ) {
        let request  = {
            departure_date_there : dateWithoutTimeZone(departure_date_there).toISOString(),
            pax_there : pax_there,
            departure_airport : departure_airport,
            arrival_airport : arrival_airport
        };
        if ( departure_date_back ) {
            setDepartureBackDate( dateWithoutTimeZone( departure_date_back ) );
            request.departure_date_back = dateWithoutTimeZone(departure_date_back).toISOString();
            request.pax_back = request.pax_there;
        }
        setDepartureDate( dateWithoutTimeZone( departure_date_there ) );
        setPax( pax_there );
        setAirportFromData( departure_airport );
        setAirportToData( arrival_airport );
        setLastRequest( request );
        if ( make_offset ) {
            setDataPickerOffset(true);
        }
        if ( make_request ) {
            GetFlightsData( request, setFlightsData, setFlightInfo, setIsLoading, setError );
            // console.log ('Запрос на поисковую выдачу, после получения свойств рейса отправлен');
        }
    }

    // Обработка нажатия на кнопку "Обратный рейс"
    function returnButtonHandler() {
        setDataPickerOffset(true);
        if ( departureBackDate ) {
            setDepartureBackDate( null );
        } else {
            setDepartureBackDate(GetFirstRequestDate(departureDate));
        }
    }

    function paxMoreChangeHandler() {
        setPax(pax + 1);
    }

    function paxLessChangeHandler() {
        if (pax > 1) { setPax(pax - 1); }
    }

    // console.log ('Получены данные по аэропортам в "search": ' + airportsData);
    // console.log ('Получены данные по рейсам в "search": ' + flightsData);
    // console.log ('Получены данные по аэропорту вылета в "search": ' + airportFromData);
    // console.log ('Получены данные по аэропорту прилета в "search": ' + airportToData);

    // Обработка перехода на информацию о рейсе из результатов поиска без перезагрузки страницы
    // Либо переход со страницы поиска, либо с панели результатов на странице информации о рейсе
    if ( flightId && flightsData && (( !flightInfo ) || (flightInfo && flightInfo.id !== flightId))) {
        flightsData.cards.forEach(item => {
            if (flightId === item.id) {
                // console.log('ID найденного предложения: ' + item.id);
                setFlightInfo(item);
                ym('reachGoal','FLIGHT_INFO');
            }
        })
    }

    // Для корректной обработки нажатия назад. Если переходим на результаты поиска, обнуляем свойства конкретного рейса
    if ( !flightId && flightInfo ) {
        setFlightInfo(null);
    }

    // Определение количества предложений
    flightsNumber = flightsData?.cards.length;

    // Определение средней длительность рейса, или длительность конкретного рейса
    if (flightInfo) {
        averageFlightTime = GetAverageFlightTime({ flightInfo : flightInfo });
    } else {
        if (flightsData) {
            averageFlightTime = GetAverageFlightTime({ flightsData : flightsData });
        }
    }

    // Если открыто окно выбора дат в десктопе, скролл отключается
    if (whichDateChoosing) {
        document.body.style.overflow = "hidden";
    } else {
        document.body.style.overflow = "scroll";
    }

    // Заголовок страницы
    if (airportToData && airportFromData) {
        pageTitle = Intl.messages['search_title_destination'] + ' ' + airportFromData?.city + ' → ' + airportToData?.city;
    } else {
        pageTitle = Intl.messages['search_title'];
    }

    return (
        <HelmetProvider>
            <Helmet>
                <title>{ pageTitle }</title>
                <meta name="description" content={ useIntl().messages['search_description'] } />
            </Helmet>
            <Header
                searchPage = { true }
            />
            <div className="flights">
                { !emptyLeg &&
                    <div className="shadow_box">
                        <SearchStatusBar
                            flightsNumber={ flightsNumber }
                            departureDate={ departureDate }
                            departureBackDate={ departureBackDate }
                            airportFromData={ airportFromData }
                            airportToData={ airportToData }
                            whichDateChoosing={ whichDateChoosing }
                            setWhichDateChoosing={ setWhichDateChoosing }
                            flightId={ flightId }
                            flightInfo={ flightInfo }
                            setFlightInfo={ setFlightInfo }
                            flightsData={ flightsData }
                            returnButtonHandler={ returnButtonHandler }
                        />
                        { whichDateChoosing &&
                            <div className="modal-window" onClick={ () => setWhichDateChoosing(null) }/>
                        }
                        <DataPicker
                            averageFlightTime={ averageFlightTime }
                            departureDate={ departureDate }
                            setDepartureDate={ setDepartureDate }
                            departureBackDate={ departureBackDate }
                            setDepartureBackDate={ setDepartureBackDate }
                            whichDateChoosing={ whichDateChoosing }
                            setWhichDateChoosing={ setWhichDateChoosing }
                            dataPickerOffset={ dataPickerOffset }
                            setDataPickerOffset={ setDataPickerOffset }
                            isLoading={ isLoading }
                        />
                    </div>
                }
                { !flightId && !emptyLeg &&
                    <SearchFilters
                        pax = { pax }
                        setPax = { setPax }
                        flightsData = { flightsData }
                        setFlightsData = { setFlightsData }
                        currency = { currency }
                        setCurrency = { setCurrency }
                        flightsNumber = { flightsNumber }
                        departureBackDate = {departureBackDate }
                        returnButtonHandler = { returnButtonHandler }
                        paxMoreChangeHandler = { paxMoreChangeHandler }
                        paxLessChangeHandler = { paxLessChangeHandler }
                    />
                }
                { emptyLeg &&
                    <UnderHeaderLine />
                }
                <RenderSearchResults
                    isLoading = { isLoading }
                    flightsData = { flightsData }
                    flightInfo = { flightInfo }
                    flightId = { flightId }
                    airportsData = { airportsData }
                    airportFromData = { airportFromData }
                    airportToData = { airportToData }
                    departureDate = { departureDate }
                    departureBackDate = { departureBackDate }
                    currency = { currency }
                    emptyLeg = { emptyLeg }
                />
            </div>
            { flightInfo &&
                <FlightInfo
                    flightInfo = { flightInfo }
                    flightsData = { flightsData }
                    isLoading = {isLoading}
                    pax = { pax }
                    setPax = { setPax }
                    departureDate = { departureDate }
                    departureBackDate = { departureBackDate }
                    currency = { currency }
                    averageFlightTime = { averageFlightTime }
                    returnButtonHandler = { returnButtonHandler }
                    paxMoreChangeHandler = { paxMoreChangeHandler }
                    paxLessChangeHandler = { paxLessChangeHandler }
                />
            }
            <Footer
                searchPage = { true }
                flightPage = { !!flightInfo }
            />
        </HelmetProvider>
    );
}
