import { useIntl, FormattedMessage } from 'react-intl';

import './AboutContent.scss';

import office1Pic from '../assets/office1.png';
import office2Pic from '../assets/office2.png';
import office3Pic from '../assets/video-cover.png';

import mikePic from '../assets/mike.png';
import juliaPic from '../assets/julia.png';

export default function AboutContent () {
    const Intl = useIntl();
    return (
        <div className="about-content">
            <div className="about-content-title"><FormattedMessage id='about_content_title' /></div>
            <div className="about-content-text">
                <div dangerouslySetInnerHTML={ {__html: Intl.messages['about_content_text']} } />
                <div className="about-content-cert1" />
                <div className="about-content-cert2" />
            </div>
            <div className="about-content-images">
                <img src={ office3Pic } />
                <img src={ office1Pic } />
                <img src={ office2Pic } />
            </div>
            <div className="about-content-title"><FormattedMessage id='about_content_team_title' /></div>
            <div className="about-content-card">
                <img src={ mikePic } />
                <div className="about-content-card-name"><FormattedMessage id='about_content_team_person1_name' /></div>
                <div className="about-content-card-text"><FormattedMessage id='about_content_team_person1_text' /></div>
            </div>
            <div className="about-content-card">
                <img src={ juliaPic } />
                    <div className="about-content-card-name"><FormattedMessage id='about_content_team_person2_name' /></div>
                    <div className="about-content-card-text"><FormattedMessage id='about_content_team_person2_text' /></div>
            </div>
        </div>
    );
}
