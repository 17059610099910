import { HelmetProvider, Helmet } from "react-helmet-async";

import { useIntl  } from 'react-intl';
import { useState, useEffect } from "react";

import Header from '../components/Header';
import Footer from '../components/Footer';
import FleetFilters from '../components/FleetFilters';
import FleetShow from '../components/FleetShow';
import GetPlanePictures from "../components/GetPlanePictures";

import { fleet } from "../json/Fleet";

export default function Fleet () {

    const [fleetData, setFleetData] = useState(fleet);
    const [imagesLoaded, setImagesLoaded] = useState(false);
    const intl = useIntl();

    useEffect(() => {
        if ( fleetData && !imagesLoaded ) {
            let modifiedFleetData = [];
            let imagesRequests = [];
            fleetData.forEach((item) => {
                let modifiedItem = Object.assign({},item);
                imagesRequests.push(
                    GetPlanePictures({ aircraft : item.number })
                        .then((result) => {
                            modifiedItem['images'] = result['images'];
                            modifiedItem['seats'] = result['seats'];
                            modifiedFleetData.push(modifiedItem);
                        })
                )
            })
            Promise.all(imagesRequests).then(() => {
                setImagesLoaded(true);
                setFleetData(modifiedFleetData);
            })
        }
    }, [])

    return (
        <HelmetProvider>
            <Helmet>
                <title>{ intl.messages['fleet_title'] }</title>
                <meta name="og:title" content={ intl.messages['fleet_title'] }/>
                <meta name="description" content={ intl.messages['fleet_description'] } />
                <meta name="og:description" content={ intl.messages['fleet_description'] }/>
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={ 'https://najet.online' + window.location.pathname } />
            </Helmet>
            <Header
                staticPage = { true }
            />
            <div className="fleet-page">
                <FleetFilters
                    fleetData = { fleetData }
                    setFleetData = { setFleetData }
                />
                { imagesLoaded &&
                    <FleetShow
                        fleetData = { fleetData }
                    />
                }
            </div>
            <Footer
                staticPage = { true }
            />
        </HelmetProvider>
    );
}
