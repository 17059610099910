import './FilterButton.scss';

export default function FilterButton ( props ) {

    let style = 'filter-button';
    if (props.style) { style = style + ' ' + props.style; }

    return (
        <div className={ style } onClick={ props.action ? () => props.action() : null } >
            <div className="filter-button-content">
                { props.leftIcon &&
                    <img className="filter-button-left-img" src={ props.leftIcon } alt={ props.label } />
                }
                <span>{ props.label }</span>
                { props.rightIcon &&
                    <img className="filter-button-right-img" src={ props.rightIcon } alt={ props.label } />
                }
            </div>
        </div>
    );
}