import './SearchStatusBar.scss';

import { useIntl, FormattedMessage, FormattedDate, FormattedTime } from 'react-intl';
import React from "react";
import { Link } from "react-router-dom";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';

import FilterButton from "./FilterButton";

import returnPicActive from "../assets/return-pic-active.svg";
import returnPic from "../assets/return-pic.svg";

export default function SearchStatusBar ( props )  {

    const Intl = useIntl();
    let requestURL;

    // Создание URL Ссылки на другие результаты поиска, на странице рейса
    if ( props.flightInfo && props.flightsData ) {
        requestURL = Intl.messages[ 'currentLocaleLink' ] + 'search/' + props.flightsData.id;
    } else {
        requestURL = Intl.messages[ 'currentLocaleLink' ];
    }

    return (
        <div>
            <div className="mobile-navigation">
                <Link to={ requestURL }>
                    <div className="mobile-navigation-quantity" >
                        { props.flightsNumber? <FormattedMessage id='variants' values={{ count: props.flightsNumber }}/> : <Skeleton className="skeleton" /> }
                    </div>
                </Link>
                <FilterButton
                    label = { Intl.messages['return_flight'] }
                    leftIcon = { props.departureBackDate ? returnPicActive : returnPic }
                    action = { () => props.returnButtonHandler() }
                />
            </div>
            <div className="info">
                <div className={ props.departureBackDate ? 'info-item info-item-departure info-item-longlist' : 'info-item info-item-departure'}>
                    <div className="info-item-caption">
                        <div className="info-item-caption-name">{ props.airportFromData?.name }</div>
                        <div className="info-item-caption-city">{ props.airportFromData?.city }</div>
                    </div>
                    <div className="info-item-code">
                        <div className="info-item-code-iata">{ props.airportFromData?.iata }</div>
                        <div className="info-item-code-icao">{ props.airportFromData?.icao }</div>
                    </div>
                </div>
                <div className={ props.departureBackDate ? 'info-item info-item-arrival info-item-longlist' : 'info-item info-item-arrival'}>
                    <div className="info-item-caption">
                        <div className="info-item-caption-name">{ props.airportToData?.name }</div>
                        <div className="info-item-caption-city">{ props.airportToData?.city }</div>
                    </div>
                    <div className="info-item-code">
                        <div className="info-item-code-iata">{ props.airportToData?.iata }</div>
                        <div className="info-item-code-icao">{ props.airportToData?.icao }</div>
                    </div>
                </div>
                <div className={ props.departureBackDate ? 'info-item info-item-last info-long-item' : 'info-item info-item-last' }>
                    <div className="info-item-caption info-item-caption-departure" onClick={ () => props.setWhichDateChoosing('there') } >
                        { props.departureBackDate &&
                            <div className="info-item-caption-direction"><FormattedMessage id='there'/></div>
                        }
                        <div className="info-item-caption-name"><FormattedDate value={ props.departureDate } day='2-digit' month='long'  />,</div>
                        <div className="info-item-caption-duration">в&nbsp;<FormattedTime value={ props.departureDate } /></div>
                    </div>
                    { props.departureBackDate &&
                        <div className="info-item-caption info-item-caption-departure" onClick={ () => props.setWhichDateChoosing('back') } >
                            <div className="info-item-caption-direction"><FormattedMessage id='back' /></div>
                            <div className="info-item-caption-name"><FormattedDate value={ props.departureBackDate } day='2-digit' month='long'  />,</div>
                            <div className="info-item-caption-duration">в&nbsp;<FormattedTime value={ props.departureBackDate } /></div>
                        </div>
                    }
                </div>
            </div>
    </div>
    );
}