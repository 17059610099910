export const fleet =  [
    {
        number : 'RA-67218',
        type : 'Challenger 850',
        year_of_creation : '2008',
        year_of_renewal : '2021',
        year_of_overhaul : '',
        max_pax : '9',
        cabin_height : '1,85',
        cabin_length : '12,25',
        interior_width : '2,49',
        wing_span : '21,21',
        fuselage_height : '6,22',
        fuselage_length : '26,77',
        flying_range : '5689',
        cruising_speed : '850',
    },
    {
        number : 'EW-500LL',
        type : 'Pilatus PC-12/47E NG',
        year_of_creation : '2020',
        year_of_renewal : '',
        year_of_overhaul : '',
        max_pax : '6',
        cabin_height : '1,47',
        cabin_length : '5,16',
        interior_width : '1,52',
        wing_span : '16,28',
        fuselage_height : '4,26',
        fuselage_length : '14,4',
        flying_range : '2700',
        cruising_speed : '528',
    },
    {
        number : 'EW-502LL',
        type : 'Pilatus PC-12/47E NG',
        year_of_creation : '2017',
        year_of_renewal : '',
        year_of_overhaul : '',
        max_pax : '6',
        cabin_height : '1,47',
        cabin_length : '5,16',
        interior_width : '1,52',
        wing_span : '16,28',
        fuselage_height : '4,26',
        fuselage_length : '14,4',
        flying_range : '2700',
        cruising_speed : '528',
    },
    {
        number : 'RA-02730',
        type : 'Hawker 850XP',
        year_of_creation : '2007',
        year_of_renewal : '2017',
        year_of_overhaul : '2017',
        max_pax : '8',
        cabin_height : '1,75',
        cabin_length : '6,50',
        interior_width : '1,82',
        wing_span : '16,50',
        fuselage_height : '5,50',
        fuselage_length : '15,6',
        flying_range : '3400',
        cruising_speed : '750',
    },
    {
        number : 'RA-02734',
        type : 'Hawker 800XP',
        year_of_creation : '2006',
        year_of_renewal : '2022',
        year_of_overhaul : '2022',
        max_pax : '8',
        cabin_height : '1,75',
        cabin_length : '6,50',
        interior_width : '1,82',
        wing_span : '15,66',
        fuselage_height : '5,36',
        fuselage_length : '15,60',
        flying_range : '3400',
        cruising_speed : '750',
    },
    {
        number : 'RA-02771',
        type : 'Hawker 700',
        year_of_creation : '1984',
        year_of_renewal : '2018',
        year_of_overhaul : '2018',
        max_pax : '8',
        cabin_height : '1,75',
        cabin_length : '6,50',
        interior_width : '1,82',
        wing_span : '14,33',
        fuselage_height : '5,36',
        fuselage_length : '15,45',
        flying_range : '3400',
        cruising_speed : '750',
    },
    {
        number : 'RA-02793',
        type : 'Hawker 800XP',
        year_of_creation : '2006',
        year_of_renewal : '2020',
        year_of_overhaul : '2020',
        max_pax : '8',
        cabin_height : '1,75',
        cabin_length : '6,50',
        interior_width : '1,82',
        wing_span : '15,66',
        fuselage_height : '5,51',
        fuselage_length : '15,6',
        flying_range : '3400',
        cruising_speed : '750',
    },
    {
        number : 'RA-02794',
        type : 'Hawker 800XP',
        year_of_creation : '2006',
        year_of_renewal : '2021',
        year_of_overhaul : '2021',
        max_pax : '8',
        cabin_height : '1,75',
        cabin_length : '6,50',
        interior_width : '1,82',
        wing_span : '15,66',
        fuselage_height : '5,51',
        fuselage_length : '15,6',
        flying_range : '3400',
        cruising_speed : '750',
    },
    {
        number : 'RA-67217',
        type : 'Challenger 300',
        year_of_creation : '2008',
        year_of_renewal : '2020',
        year_of_overhaul : '2020',
        max_pax : '9',
        cabin_height : '1,85',
        cabin_length : '8,72',
        interior_width : '2,19',
        wing_span : '19,46',
        fuselage_height : '6,2',
        fuselage_length : '20,92',
        flying_range : '5741',
        cruising_speed : '850',
    },
    {
        number : 'RA-67131',
        type : 'Global 5000',
        year_of_creation : '2010',
        year_of_renewal : '',
        year_of_overhaul : '',
        max_pax : '13',
        cabin_height : '1,91',
        cabin_length : '12,94',
        interior_width : '2,49',
        wing_span : '28,6',
        fuselage_height : '7,7',
        fuselage_length : '29,5',
        flying_range : '8890',
        cruising_speed : '907',
    },
    {
        number : 'RA-67233',
        type : 'Challenger 850',
        year_of_creation : '2012',
        year_of_renewal : '2019',
        year_of_overhaul : '2019',
        max_pax : '14',
        cabin_height : '1,85',
        cabin_length : '12,25',
        interior_width : '2,49',
        wing_span : '21,21',
        fuselage_height : '6,22',
        fuselage_length : '26,77',
        flying_range : '5206',
        cruising_speed : '819',
    },
];