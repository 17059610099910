import { HelmetProvider, Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";

import Header from '../components/Header';
import FirstScreen from "../components/FirstScreen";
import SeoBBlock from "../components/SeoBBlock";
import SeoSpecItems from "../components/SeoSpecItems";
import SeoFaq from "../components/SeoFaq";
import SeoSearchResults from "../components/SeoSearchResults";
import GetAirportsData from "../components/GetAirportsData";
import SeoJet from "../components/SeoJet";
import Footer from "../components/Footer";

import seoHawker from "../assets/seo-hawker.png";
import seoCititation from "../assets/seo-cititation.png";
import seoGlobal from "../assets/seo-global.png";

export default function Seo( props ){
    const [airportsData, setAirportsData] = useState(null);

    useEffect(() => {
        GetAirportsData( setAirportsData );
    }, [])

    let canonicalPath = () => {
        if (window.location.pathname.substring(window.location.pathname.length - 1) !== "/") {
            return window.location.pathname + "/";
        } else {
            return window.location.pathname;
        }
    }

    const intlflights = [
        {
            planeImage : seoHawker,
            planeType : 'midsize jets',
            destination : props.texts.spec_items_intl_destination1,
            price : props.texts.spec_items_intl_price1,
            link : props.texts.spec_items_intl_link1,
            action: props.texts.spec_items_intl_action,
        },
        {
            planeImage : seoCititation,
            planeType : 'super midsize jets',
            destination : props.texts.spec_items_intl_destination2,
            price : props.texts.spec_items_intl_price2,
            link : props.texts.spec_items_intl_link2,
            action: props.texts.spec_items_intl_action,
        },
        {
            planeImage : seoGlobal,
            planeType : 'midsize jets',
            destination : props.texts.spec_items_intl_destination3,
            price : props.texts.spec_items_intl_price3,
            link : props.texts.spec_items_intl_link3,
            action: props.texts.spec_items_intl_action,
        },
        {
            planeImage : seoHawker,
            planeType : 'midsize jets',
            destination : props.texts.spec_items_intl_destination4,
            price : props.texts.spec_items_intl_price4,
            link : props.texts.spec_items_intl_link4,
            action: props.texts.spec_items_intl_action,
        },
    ];

    const domflights = [
        {
            planeImage : seoHawker,
            planeType : 'midsize jets',
            destination : props.texts.spec_items_dom_destination1,
            price : props.texts.spec_items_dom_price1,
            link : props.texts.spec_items_dom_link1,
            action: props.texts.spec_items_dom_action,
        },
        {
            planeImage : seoCititation,
            planeType : 'super midsize jets',
            destination : props.texts.spec_items_dom_destination2,
            price : props.texts.spec_items_dom_price2,
            link : props.texts.spec_items_dom_link2,
            action: props.texts.spec_items_dom_action,
        },
        {
            planeImage : seoGlobal,
            planeType : 'midsize jets',
            destination : props.texts.spec_items_dom_destination3,
            price : props.texts.spec_items_dom_price3,
            link : props.texts.spec_items_dom_link3,
            action: props.texts.spec_items_dom_action,
        },
        {
            planeImage : seoHawker,
            planeType : 'midsize jets',
            destination : props.texts.spec_items_dom_destination4,
            price : props.texts.spec_items_dom_price4,
            link : props.texts.spec_items_dom_link4,
            action: props.texts.spec_items_dom_action,
        },
    ];

    return (
        <HelmetProvider>
            <Helmet>
                <title>{ props.texts.title }</title>
                <meta name="description" content={ props.texts.description }/>
                <meta name="keywords" content={ props.texts.keywords }/>
                <meta name="og:title" content={ props.texts.title }/>
                <meta name="og:description" content={ props.texts.description }/>
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={ 'https://najet.online' + canonicalPath() } />
            </Helmet>
            <Header />
            <FirstScreen
                header = { props.texts.header }
                airportsData = { airportsData }
            />
            <SeoBBlock
                header={ props.texts.bblock_header }
                caption={ props.texts.bblock_caption }
                message1={ props.texts.bblock_message1 }
                message2={ props.texts.bblock_message2 }
                message3={ props.texts.bblock_message3 }
                message4={ props.texts.bblock_message4 }
            />
            <SeoSpecItems
                header = { props.texts.spec_items_intl_header }
                flights = { intlflights }
                index = { 1 }
            />
            <SeoFaq
                header={ props.texts.faq_header }
                caption={ props.texts.faq_caption }
                message1={ props.texts.faq_message1 }
                message2={ props.texts.faq_message2 }
                message3={ props.texts.faq_message3 }
                message4={ props.texts.faq_message4 }
                message5={ props.texts.faq_message5 }
                message6={ props.texts.faq_message6 }
            />
            <SeoSearchResults
                header = { props.texts.search_results_header }
                caption = { props.texts.search_results_caption }
                airportsData = { airportsData }
            />
            <SeoJet
                header = { props.texts.jet_header }
                caption = { props.texts.jet_caption }
            />
            <SeoSpecItems
                header = { props.texts.spec_items_dom_header }
                flights = { domflights }
                index = { 2 }
            />
            <Footer
                staticPage = { true }
                leftText = { props.texts.footer_left_text }
                rightText = { props.texts.footer_right_text }
            />
        </HelmetProvider>
    );
}