import { HelmetProvider, Helmet } from "react-helmet-async";
import { useIntl  } from 'react-intl';

import Header from '../components/Header';
import Footer from '../components/Footer';
import UnderHeaderLine from "../components/UnderHeaderLine";

import Restrictions from "../components/Restrictions";
import AboutContent from "../components/AboutContent";
import ContactsContent from '../components/ContactsContent';
import PartnersContent from '../components/PartnersContent';
import HowItWorks from "../components/HowItWorks";
import ErrorContent from "../components/ErrorContent";

export default function StaticPage (props ) {
    const intl = useIntl();
    let canonicalPath = () => {
        if (window.location.pathname.substring(window.location.pathname.length - 1) !== "/") {
            return window.location.pathname + "/";
        } else {
            return window.location.pathname;
        }
    }


    return (
        <HelmetProvider>
            <Helmet>
                <title>{ intl.messages[props.page + '_title'] }</title>
                <meta name="og:title" content={ intl.messages[props.page + '_title'] }/>
                <meta name="description" content={ intl.messages[props.page + '_description'] } />
                <meta name="og:description" content={ intl.messages[props.page + '_description'] } />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={ 'https://najet.online' + canonicalPath() } />
            </Helmet>
            <Header
                staticPage = { true }
                line = { true }
            />
            <UnderHeaderLine />
            <div className={ props.page !== 'howitworks' ? 'static-page' : ''}>
                { props.page === 'contacts' &&
                    <ContactsContent />
                }
                { props.page === 'restrictions' &&
                    <Restrictions />
                }
                { props.page === 'about' &&
                    <AboutContent />
                }
                { props.page === 'partners' &&
                    <PartnersContent />
                }
                { props.page === 'howitworks' &&
                    <HowItWorks />
                }
                { props.page === 'not_found' &&
                    <ErrorContent  />
                }
            </div>
            <Footer
                staticPage = { true }
            />
        </HelmetProvider>
    );
}

