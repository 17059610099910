import { PATH } from "../config";

export default function GetAirportsData (setAirportsData) {
    let airportsData;
    let url = new URL( PATH.AIRPORTS );

    fetch( url )
        .then(response => response.json())
        .then(
            (result) => {
                airportsData = result;
                setAirportsData(airportsData);
                return true;
            },
            (error) => {
                return error;
            });
}