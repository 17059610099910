import { PATH } from "../config";
import './RenderItem.scss';

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';

import { useIntl, FormattedMessage } from 'react-intl';
import { Link } from "react-router-dom";
import React, { Fragment } from "react";

import favorites from "../assets/favorites.png";

import GetAiportById from "./GetAiportById";
import FormatItemDate from "./FormatItemDate";
import dateWithoutTimeZone from "./DateWithoutTimeZone";
import GetAverageFlightTime from "./GetAverageFlightTime";

export default function RenderItem( props )  {

    const Intl = useIntl();

    let price;

    let displayedPrice;
    let displayedDepartureLocalDate;
    let displayedArrivalLocalDate;

    let route1;
    let direction1;
    let route2;
    let direction2;

    // Для предзагрузки, в случае наличия обратного рейса
    if (props.departureBackDate && props.isLoading) {
        route2 = true;
        direction1 = 'there';
        direction2 = 'back';
    }

    if ( !props.isLoading ) {
        // console.log( 'Обработка item c id: ' + props.item );
        if ( props.currency === 'rub') {
            if ( props.item.routes[1] ) { price = props.item.routes[0].price.rub + props.item.routes[1].price.rub } else { price = props.item.routes[0].price.rub }
            displayedPrice = Intl.formatNumber(price) + ' ₽';
        }
        if ( props.currency === 'usd') {
            if ( props.item.routes[1] ) { price = props.item.routes[0].price.usd + props.item.routes[1].price.usd } else { price = props.item.routes[0].price.usd }
            displayedPrice = Intl.formatNumber(price) + ' $';
        }
        if ( props.currency === 'eur') {
            if ( props.item.routes[1] ) { price = props.item.routes[0].price.eur + props.item.routes[1].price.eur } else { price = props.item.routes[0].price.eur }
            displayedPrice = Intl.formatNumber(price) + ' €';
        }
        if ( props.item.routes[0] ) { route1 = props.item.routes[0] }
        if ( props.item.routes[1] && props.departureBackDate ) {
            route2 = props.item.routes[1];
            direction1 = 'there';
            direction2 = 'back';
            let departureDateLocal = dateWithoutTimeZone(route1.departure_date);
            displayedDepartureLocalDate = departureDateLocal.toLocaleString("ru", { hour: 'numeric', minute:'numeric', day: 'numeric', month: 'numeric' });
            let arrivalDateLocal = dateWithoutTimeZone(route2.departure_date);
            displayedArrivalLocalDate = arrivalDateLocal.toLocaleString("ru", { hour: 'numeric', minute:'numeric', day: 'numeric', month: 'numeric' });
        }
    }

    // Обработка вида результатов в зависимости от страницы. Результаты поиска, или информация о рейсе
    let itemClass;
    if ( props.flightId ) {
        if ( props.departureBackDate ) {
            itemClass = "flightinfo-back-item flightinfo-item results-item";
        } else {
            itemClass = "flightinfo-item results-item";
        }
        if ( props.flightInfo &&
            !props.isLoading &&
            (
                ( props.flightInfo.routes[0].price.rub !== props.item.routes[0].price.rub ) ||
                ( props.flightInfo.routes[0].aircraft !== props.item.routes[0].aircraft ) ||
                ( props.flightInfo.routes[1]?.price.rub !== props.item.routes[1]?.price.rub ) ||
                ( props.flightInfo.routes[1]?.aircraft !== props.item.routes[1]?.aircraft )
            )) {
            itemClass = itemClass + " results-item-unselected";
        }
    } else {
        itemClass = "results-item";
        if (props.first) { itemClass = itemClass + " results-item-first"; }
        if (props.last) { itemClass = itemClass + " results-item-last"; }
    }

    function RenderRoute ( props ) {

        let aircraftClass;
        let planeCabinPhotoStyle;
        let averageFlightTime;

        let warningMessage;
        let departureAirport;
        let departureAirportChanged;
        let arrivalAirport;
        let arrivalAirportChanged;
        let departureDate;
        let departureDateChanged;

        let itemContentClass = 'results-item-content';

        if ( !props.isLoading ) {
            // Картинка салона
            if (props.item.aircraft_class) { aircraftClass = props.item.aircraft_class; } else { aircraftClass = 'Jet'; }
            if (props.item.aircraft_picture_urls.salon ) {
                planeCabinPhotoStyle  = {
                    background: 'url(' + PATH.PICTURES + props.item.aircraft_picture_urls.salon + ') left -10px top/auto 100% no-repeat' };
            } else {
                planeCabinPhotoStyle  = {
                    background: 'url(' + PATH.PICTURES + props.item.aircraft_picture_url + ') center top/auto 100% no-repeat'};
            }
            // Данные аэропортов
            departureAirport = GetAiportById( props.item?.departure_airport, props.airportsData, Intl.locale );
            arrivalAirport = GetAiportById( props.item?.arrival_airport, props.airportsData, Intl.locale );
            // Вычисление изменений относительно заданных фильтров, чтобы выдать предупреждение
            if ( !props.emptyLeg ) {
                if ( props.airportFromData.icao !== props.item.departure_airport ) {
                    warningMessage = Intl.messages['airportChanged'];
                    departureAirportChanged = true;
                }
                if ( props.airportToData.icao !== props.item.arrival_airport ) {
                    warningMessage = Intl.messages['airportChanged'];
                    arrivalAirportChanged = true;
                }
                departureDate = dateWithoutTimeZone(props.item.departure_date);
                if ( departureDate.toLocaleString("ru", { day: 'numeric', hour:'numeric', minute:'numeric'}) !== props.departureDate.toLocaleString("ru", { day: 'numeric', hour:'numeric', minute:'numeric'})) {
                    if (!warningMessage) {
                        warningMessage = Intl.messages['timeChanged'];
                    } else {
                        warningMessage = Intl.messages['timeAirportChanged'];
                    }
                    departureDateChanged = true;
                }
            } else {
                warningMessage = Intl.formatDate(props.item.departure_date, {day: 'numeric', month: 'long'});
            }
            // Расчет полетного времени для мобильной версии сдвоенных(туда/обратно) карточек
            averageFlightTime = GetAverageFlightTime({
                departureDate: props.item.arrival_date,
                arrivalDate: props.item.arrival_date
            });
        }

        if (props.direction === 'there') { itemContentClass = itemContentClass + ' results-item-content-there'}
        if (props.direction === 'back') { itemContentClass = itemContentClass + ' results-item-content-back'}

        return (
            <div className={ itemContentClass } >
                <div className="results-item-content-mask">
                    <div className="results-item-content-photo" style= { planeCabinPhotoStyle }>
                        { !props.isLoading? null : <Skeleton className="skeleton" /> }
                    </div>
                </div>
                { (props.direction && !props.isLoading) &&
                    <div className={'results-item-content-mask-' + props.direction } />
                }
                <div className="results-item-content-conditions">
                    <div className="results-item-content-conditions-plane-name">
                        { !props.isLoading? props.item.aircraft_type : <Skeleton className="skeleton" /> }
                    </div>
                    <div className={ props.emptyLeg ? 'results-item-content-conditions-plane-type results-item-content-conditions-plane-type-no-display-mobile' : 'results-item-content-conditions-plane-type'}>
                        { !props.isLoading? aircraftClass : <Skeleton className="skeleton" /> }
                    </div>
                    { props.emptyLeg &&
                        <div className='results-item-content-conditions-plane-el'>
                            { !props.isLoading? departureAirport?.city + ' → ' + arrivalAirport?.city : <Skeleton className="skeleton" /> }
                        </div>
                    }
                    <div className={ warningMessage ? 'results-item-content-conditions-warning' : 'results-item-content-conditions-nowarning' } >{ warningMessage }</div>
                    <div className="results-item-content-conditions-directions">
                        <div className="results-item-content-conditions-directions-from">
                            <div className={ departureAirportChanged ? 'results-item-content-conditions-directions-name results-item-content-conditions-directions-warning' : 'results-item-content-conditions-directions-name'} >
                                { !props.isLoading? departureAirport?.name : <Skeleton className="skeleton" />}
                            </div>
                            <div className={ departureDateChanged ? 'results-item-content-conditions-directions-details results-item-content-conditions-directions-warn' : 'results-item-content-conditions-directions-details' } >
                                { !props.isLoading? departureAirport?.city : <Skeleton className="skeleton" />}
                                { !props.isLoading &&
                                    <Fragment>
                                        <br/>
                                        <FormattedMessage id='in' />&nbsp;
                                        <FormatItemDate date = { dateWithoutTimeZone(props.item.departure_date) } />
                                    </Fragment>
                                }
                            </div>
                        </div>
                        <div className="results-item-content-conditions-directions-to">
                            <div  className={ arrivalAirportChanged ? 'results-item-content-conditions-directions-name results-item-content-conditions-directions-warning' : 'results-item-content-conditions-directions-name'}>
                                { !props.isLoading? arrivalAirport.name : <Skeleton className="skeleton" /> }
                            </div>
                            <div className="results-item-content-conditions-directions-details">
                                { !props.isLoading? arrivalAirport.city : <Skeleton className="skeleton" /> }
                                { !props.isLoading &&
                                    <Fragment>
                                        <br/>
                                        <FormattedMessage id='in' />&nbsp;
                                        <FormatItemDate date = { dateWithoutTimeZone(props.item.arrival_date) } />
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="results-item-content-conditions-mobile">
                        <div className="results-item-content-conditions-mobile-price">
                            { !props.isLoading? props.price : <Skeleton className="skeleton" /> }
                        </div>
                        <div className="results-item-content-conditions-mobile-year">
                            { !props.isLoading &&
                                <Fragment>{ !props.emptyLeg ? props.item.aircraft_year_of_creation :  Intl.formatDate(props.item.departure_date_local, {day: 'numeric', month: 'numeric', year: '2-digit'})}</Fragment>
                            }
                            { props.isLoading &&
                                <Skeleton className="skeleton" />
                            }
                        </div>
                        <div className="results-item-content-conditions-mobile-splitter">
                            ・
                        </div>
                        <div className="results-item-content-conditions-mobile-seat">
                            { !props.isLoading? <FormattedMessage id='seats' values={{ count: props.item.max_pax }} /> : <Skeleton className="skeleton" /> }
                        </div>
                        <div className="results-item-content-conditions-mobile-splitter">
                            ・
                        </div>
                        <div className="results-item-content-conditions-mobile-flight-time">
                            { !props.isLoading? averageFlightTime?.toLocaleString("ru", {  hour: 'numeric', minute: 'numeric'}) : <Skeleton className="skeleton" /> }
                        </div>
                    </div>
                </div>
                <div className="results-item-content-options">
                    <div className="results-item-content-options-main">
                        <div className="results-item-content-options-main-price">
                            { !props.isLoading? props.price : <Skeleton className="skeleton"/> }
                        </div>
                        <div className="results-item-content-options-main-favorites">
                            { props.direction !== 'back' &&
                                <img src={ favorites } alt={ Intl.messages['favorites'] } />
                            }
                        </div>
                    </div>
                    <div className="results-item-content-options-plane">
                        <div className="results-item-content-options-plane-photo">
                            { !props.isLoading? <img height="100%" src={  PATH.PICTURES + props.item.aircraft_picture_urls.avatar } alt={ props.item.aircraft_type } /> : <Skeleton className="skeleton" /> }
                        </div>
                        <div className="results-item-content-options-plane-year">
                            { !props.isLoading? props.item.aircraft_year_of_creation : <Skeleton className="skeleton" /> }
                        </div>
                        <div className="results-item-content-conditions-splitter">
                            ・
                        </div>
                        <div className="results-item-content-options-plane-seat">
                            { !props.isLoading? <FormattedMessage id='seats' values={{ count: props.item.max_pax }}/> :  <Skeleton className="skeleton" /> }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Link to={ !props.isLoading? Intl.messages['currentLocaleLink'] + 'flight/' + props.item.id : '' }>
            <div className={ itemClass } id={ !props.isLoading? props.item.id : ''}>
                <RenderRoute
                    item = { route1 }
                    airportFromData = { props.airportFromData }
                    airportToData = { props.airportToData }
                    airportsData = { props.airportsData }
                    departureDate = { props.departureDate }
                    direction = { direction1 }
                    price = { displayedPrice }
                    isLoading = { props.isLoading }
                    emptyLeg = { props.emptyLeg }
                />
                { route2 &&
                    <RenderRoute
                        item = { route2 }
                        airportFromData = { props.airportToData }
                        airportToData = { props.airportFromData }
                        airportsData = { props.airportsData }
                        departureDate = { props.departureBackDate }
                        direction = { direction2 }
                        price = { '' }
                        isLoading = { props.isLoading }
                    />
                }
                { route2 &&
                    <div className="results-item-content-mobile-infoblock">
                        <span className="results-item-content-mobile-infoblock-date">
                            { !props.isLoading
                                ? '← ' + displayedDepartureLocalDate + ' ・ → ' + displayedArrivalLocalDate
                                : <Skeleton className="skeleton"/>
                            }
                        </span>
                        <span className="results-item-content-mobile-infoblock-price">
                            { !props.isLoading
                                ? displayedPrice
                                : <Skeleton className="skeleton"/>
                            }
                        </span>
                    </div>
                }
            </div>
        </Link>
    );
}
