export const ARENDA_BIZNES_DZHETA= {
    title : 'Аренда бизнес джета. Мгновенное предложение онлайн. NAJET.',
    description : 'Арендуйте бизнес джет и ваш полет станет максимально комфортным, безопасным и быстрым. Стоимость, цена, удобнай сервис бронирования бизнес джетов.',
    keywords: 'аренда бизнес джета',

    header : 'Онлайн-сервис аренды бизнес джета',

    bblock_header : 'Преимущества аренды бизнес джета',
    bblock_caption : 'Арендуя бизнес джет, человек приобретает следующие условия в полёте:',
    bblock_message1 : 'Подбор самолета под ваши запросы. Заказчик получает предложения на все бизнес джеты, которые могут выполнить заявленный перелет. Заказчик сам выбирает самолет который подходит ему по всем параметрам, включая вместимость, дальность полета, оборудование и комфортабельность салона, класс обслуживания и многое другое',
    bblock_message2 : 'Высокий уровень безопасности. Флот деловой авиации комплектуется высококлассными воздушными судами, а пилоты проходят строгий отбор и периодическое повышение квалификации. Использование бизнес джета, как и частного самолета это залог абсолютной безопасности',
    bblock_message3 : 'Приватность и комфорт. На борту нет посторонних пассажиров, оборудование салона обеспечивает максимально комфортные условия, во время перелета клиентам предлагается обслуживание элитного уровня ',
    bblock_message4 : 'Свобода путешествий. Вы сами определяете время и маршрут полета бизнес-джета («частного самолета»). Заказать бизнес джет можно с отправлением уже через несколько часов с момента оформления заказа ',

    faq_header : 'Выбор бизнес джета',
    faq_message1 : 'Легкие турбовинтовые (4-6 пассажиров). Предназначены для полетов на расстояния до 1300 км со скоростью до 400 км/час.',
    faq_message2 : 'Легкие реактивные(4-9 пассажиров). Летают со скоростью до 800 км/час на расстояние до 2000 км.',
    faq_message3 : 'Средние реактивные (8-9 пассажиров). Преодолевают расстояния до 3000 км со скоростью до 900 км/ч. Отличаются более просторным и комфортабельным салоном',
    faq_message4 : 'Суперсредние реактивные (10-12  пассажиров). Способны летать на расстояния до 7000 км, развивают крейсерскую скорость до 900 км/ч',
    faq_message5 : 'Реактивные дальнемагистральные (9-19 пассажиров). Пролетают до 10000 км и более. Салон такого бизнес-джета отличается повышенной комфортабельностью, имеет спальные места, зону отдыха и другие зоны.',
    faq_message6 : 'Есть и большие бизнес джеты (типа ТУ-204, Boeing 757 и др), салон которых превращен в роскошные апартаменты VIP класса',
    faq_caption : 'Стоимость аренды бизнес джета отличается в зависимости от пассажировместимости, дальности полета, особенностей конструкции, комфортабельности салона и даты его обновления. Как правило, чем больше джет, тем выше стоимость летного часа',

    spec_items_intl_header : 'Международные перелеты на арендованном бизнес джете',
    spec_items_intl_destination1 : 'Москва — Минск',
    spec_items_intl_price1 : 'от 15 000 €',
    spec_items_intl_link1: 'search/UUWW/UMMS',
    spec_items_intl_destination2 : 'Москва — Баку',
    spec_items_intl_price2 : 'от 55 000 €',
    spec_items_intl_link2: 'search/UUWW/UBBB',
    spec_items_intl_destination3 : 'Москва — Нур-Султан',
    spec_items_intl_price3 : 'от 50 000 €',
    spec_items_intl_link3: 'search/UUWW/UACC',
    spec_items_intl_destination4 : 'Москва — Стамбул',
    spec_items_intl_price4 : 'от 70 000 €',
    spec_items_intl_link4: 'search/UUWW/LTFM',
    spec_items_intl_action : 'Смотреть предложения',

    spec_items_dom_header : 'Региональные перелеты на арендованном бизнес джете',
    spec_items_dom_destination1 : 'Москва — Сочи',
    spec_items_dom_price1 : 'от 55 000 €',
    spec_items_dom_link1: 'search/UUWW/URSS',
    spec_items_dom_destination2 : 'Москва — Санкт-Петербург',
    spec_items_dom_price2 : 'от 14 000 €',
    spec_items_dom_link2: 'search/UUWW/ULLI',
    spec_items_dom_destination3 : 'Москва — Казань',
    spec_items_dom_price3 : 'от 17 000 €',
    spec_items_dom_link3: 'search/UUWW/UWKD',
    spec_items_dom_destination4 : 'Москва — Тюмень',
    spec_items_dom_price4 : 'от 40 000 €',
    spec_items_dom_link4: 'search/UUWW/USTR',
    spec_items_dom_action : 'Смотреть предложения',

    search_results_header : 'Выгодные предложения',
    search_results_caption : 'Особо выгодное предложение от авиакомпаний, которые совершают пустой перелет по вашему маршруту:',

    jet_header : 'Безопасность перелета на бизнес джете',
    jet_caption : 'На бизнес джете действительно безопасно и надёжно летать. Производитель и авиакомпания на протяжении всего жизненного цикла самолета проводят на бизнес джете специальные тесты, организуют техническое обслуживание и доработку систем. VIP самолёты всегда пребывают в идеальном состоянии. Авиакомпании – партнеры Сервиса NAJET – самые надежные и проверенные перевозчики в стране, имеющие все необходимые лицензии и сертификаты, подтверждающие безопасность эксплуатируемых джетов. ',

    footer_left_text: 'Сервис NAJET это самый быстрый способ узнать конкретное возможное расписания полета бизнес джета и точную стоимость аренды. Поисковик на сайте сервиса NAJET работает в режиме онлайн. То есть вы получите необходимую информацию о возможных для аренды бизнес джета в течение секунд без всяких дополнительных регистраций. Cервис аренды бизнес джета NAJET позволяет получать мгновенное предложение с ценой и фото самолета онлайн.',
    footer_right_text: ' Арендуйте бизнес джет с NAJET, если вы хотите отправиться на следующую деловую встречу, на частный остров или на выходные в полном комфорте и уединении — без головной боли или хлопот. Ведь привычные многим регулярные авиарейсы иногда просто не в состоянии полноценно удовлетворить потребности клиента. С помощью NAJET вы сможете воспользоваться услугой аренды бизнес джета без всякого труда. Процесс занимает не больше времени, чем привычный заказ такси.',
}