import { useIntl, FormattedMessage } from 'react-intl';
import { useState } from "react";

import './FleetShow.scss';

import left_arrow_pic from '../assets/fleet-left-arrow.png';
import right_arrow_pic from '../assets/fleet-right-arrow.png';

import { PATH } from "../config";

export default function FleetShow ( props ) {

    const [seatsShow, setSeatsShow] = useState();
    const Intl = useIntl();

    let scrollMoveFleetGallefy = 50;

    function ShowSeats ( seats ) {
        if ( seats ) {
            document.body.style.overflow = "hidden"
            setSeatsShow(seats);
        } else {
            document.body.style.overflow = "scroll"
            setSeatsShow(null);
        }
    }

    function FleetGalleryRightHandler(event) {
        let galleryTarget = 'fleet-gallery-' + event.target.id;
        document.getElementById(galleryTarget).scrollBy(scrollMoveFleetGallefy,0);
    }

    function FleetGalleryLeftHandler(event) {
        let galleryTarget = 'fleet-gallery-' + event.target.id;
        document.getElementById(galleryTarget).scrollBy(-scrollMoveFleetGallefy,0);
    }

    function RenderGallery( props ) {

        let controlId = 'fleet-gallery-control-' + props.id;

        if ( props.items ) {
            return (
                <div className="fleet-gallery" id={ controlId }>
                    { props.items.map((item,i) => (
                        <img
                            key = { i }
                            src = { item.thumbnail }
                            alt = { props.type + ' ' + i }
                        />
                    ))
                    }
                </div>
            );
        } else {
            return null;
        }
    }

    function RenderFleetItem( props ) {

        let fleetItemClass;

        let year_of_creation;
        let year_of_overhaul;
        let year_of_renewal;

        let controlId = 'control-' + props.index;

        if ( props.item.year_of_creation !== 0 ) { year_of_creation = props.item.year_of_creation; }
        if ( props.item.year_of_overhaul !== 0 ) {
            year_of_overhaul = props.item.year_of_overhaul;
        } else {
            if ( props.item.year_of_renewal !== 0 ) { year_of_renewal = props.item.year_of_renewal; }
        }

        if ((props.index % 2) === 0) {
            fleetItemClass = "fleet-plane-card fleet-plane-card-margin"
        } else {
            fleetItemClass = "fleet-plane-card"
        }

        return (
            <div className={ fleetItemClass } >
                <div className="fleet-plane-card-header">{ props.item.type }</div>
                <div className="fleet-plane-card-shortinfo">
                    <span><FormattedMessage id='seats' values={ { count : props.item.max_pax } }/></span>
                    { year_of_creation &&
                        <span> · { year_of_creation } <FormattedMessage id='year_made_short'/></span>
                    }
                    { year_of_overhaul &&
                        <span> · <FormattedMessage id='year_overhaul'/> { year_of_overhaul }</span>
                    }
                    { year_of_renewal &&
                        <span> · <FormattedMessage id='salon_rebuild'/> { year_of_renewal }</span>
                    }
                </div>
                <RenderGallery
                    id = { props.index }
                    items = { props.item.images }
                    type = { props.item.type }
                />
                <div className="fleet-gallery-control">
                    <span onClick={ (event) => FleetGalleryLeftHandler(event) }><img src={ left_arrow_pic } id={ controlId } alt={ Intl.messages['left'] } /></span>
                    <span onClick={ (event) => FleetGalleryRightHandler(event) }><img src={ right_arrow_pic } id={ controlId } alt={ Intl.messages['right'] } /></span>
                </div>
                <div className="fleet-card-specs">
                    <div className="fleet-card-specs-label"><FormattedMessage id='specs_short' /></div>
                </div>
                <div className="fleet-card-specs-grid-wrapper">
                    <div className="fleet-card-specs-grid">
                        <div className="fleet-card-specs-item  fleet-card-specs-item-left">
                            <div className="fleet-card-specs-item-label"><FormattedMessage id="salon_height_text" /></div>
                            <div className="fleet-card-specs-item-digit">
                                { props.item.cabin_height }&nbsp;
                                <FormattedMessage id="spec_meters" />
                            </div>
                        </div>
                        <div className="fleet-card-specs-item">
                            <div className="fleet-card-specs-item-label"><FormattedMessage id="wings_text" /></div>
                            <div className="fleet-card-specs-item-digit">
                                { props.item.wing_span }&nbsp;
                                <FormattedMessage id="spec_meters" />
                            </div>
                        </div>
                        <div className="fleet-card-specs-item fleet-card-specs-item-left">
                            <div className="fleet-card-specs-item-label"><FormattedMessage id="salon_length_text" /></div>
                            <div className="fleet-card-specs-item-digit">
                                { props.item.cabin_length }&nbsp;
                                <FormattedMessage id="spec_meters" />
                            </div>
                        </div>
                        <div className="fleet-card-specs-item">
                            <div className="fleet-card-specs-item-label"><FormattedMessage id="height_text" /></div>
                            <div className="fleet-card-specs-item-digit">
                                { props.item.fuselage_height }&nbsp;
                                <FormattedMessage id="spec_meters" />
                            </div>
                        </div>
                        <div className="fleet-card-specs-item fleet-card-specs-item-left">
                            <div className="fleet-card-specs-item-label"><FormattedMessage id="salon_width_text" /></div>
                            <div className="fleet-card-specs-item-digit">
                                { props.item.interior_width }&nbsp;
                                <FormattedMessage id="spec_meters" /></div>
                        </div>
                        <div className="fleet-card-specs-item">
                            <div className="fleet-card-specs-item-label"><FormattedMessage id="length_text" /></div>
                            <div className="fleet-card-specs-item-digit">
                                { props.item.fuselage_length }&nbsp;
                                <FormattedMessage id="spec_meters" />
                            </div>
                        </div>
                        <div className="fleet-card-specs-item fleet-card-specs-item-left">
                            <div className="fleet-card-specs-item-label"><FormattedMessage id="range" /></div>
                            <div className="fleet-card-specs-item-digit">
                                { props.item.flying_range }&nbsp;
                                <FormattedMessage id="range_spec" /></div>
                        </div>
                        <div className="fleet-card-specs-item">
                            <div className="fleet-card-specs-item-label"><FormattedMessage id="speed" /></div>
                            <div className="fleet-card-specs-item-digit">
                                { props.item.cruising_speed }&nbsp;
                                <FormattedMessage id="speed_spec" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fleet-card-bottom">
                    <div className="fleet-card-bottom-button" onClick={() => ShowSeats(props.item.seats) }>
                        <div className="fleet-card-bottom-button-content">
                            <FormattedMessage id="salon_label" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="fleet-show">
            { seatsShow &&
                <div className="modal-window" onClick={() => ShowSeats(null)}>
                    <div className="fleet-show-seats">
                        <img src={ PATH.PICTURES + seatsShow } />
                    </div>
                </div>
            }
            { props.fleetData.map((item,i) => (
                <RenderFleetItem
                    key = { i }
                    index = { i }
                    item = { item }
                />
                ))
            }
        </div>
    );
}
