import { PATH } from "../config";

export default async function GetPlanePictures ( props ) {

    let url = new URL( PATH.PICTURES_API ) + props.aircraft;
    let planePictures = [];
    let fleetPictures = {};

    return fetch( url )
        .then(response => response.json())
        .then((result) => {
            // Преобразование данных по полученным фото в формат для фотогаллереи react-image-gallery
            planePictures = result.gallery.map((item) => {
                return {
                    thumbnail : PATH.PICTURES + item.thumb,
                    original : PATH.PICTURES + item.image,
                };
            });
            // Если галлерия пустая, то добавляем в галерею фото салона
            if (planePictures.length === 0) {
                planePictures.push(
                    {
                        thumbnail : PATH.PICTURES + result.salon,
                        original : PATH.PICTURES + result.salon,
                    },
                )
            }
            // console.log( 'Данные по предложению получены в GetPlanePictures: ' + JSON.stringify( planePictures ));
            if ( props.setPlanePictures ) {
                props.setPlanePictures( planePictures );
                return true;
            } else {
                fleetPictures['images'] = planePictures;
                fleetPictures['seats'] = result['seats'];
                return fleetPictures;
            }
            },
            (error) => {
                if ( props.setError ) { return props.setError(error); } else { return false }
            });
}