export const CHASTNYI_REJS = {
    title : 'Частный рейс. Мгновенное предложение онлайн. NAJET.',
    description : 'Частный рейс всегда должен быть идеальным! Сервис онлайн поиска частных рейсов NAJET мгновенно поможет Вам подобрать наиболее оптимальный вариант перелета по стоимости и типу самолета.',
    keywords: 'частный рейс',

    header : 'Онлайн-сервис поиска и заказа частного рейса',

    bblock_header : 'Преимущества частных рейсов',
    bblock_caption : 'Заказывая частный рейс, пассажир приобретает следующие условия в полёте:',
    bblock_message1 : 'Подбор самолета под ваши запросы. Заказчик получает предложения на все бизнес джеты, которые могут выполнить заявленный перелет. Заказчик сам выбирает самолет который подходит ему по всем параметрам, включая вместимость, дальность полета, оборудование и комфортабельность салона, класс обслуживания и многое другое',
    bblock_message2 : 'Высокий уровень безопасности. Флот деловой авиации комплектуется высококлассными воздушными судами, а пилоты проходят строгий отбор и периодическое повышение квалификации. Использование бизнес джета, как и частного самолета это залог абсолютной безопасности',
    bblock_message3 : 'Приватность и комфорт. На борту нет посторонних пассажиров, оборудование салона обеспечивает максимально комфортные условия, во время перелета клиентам предлагается обслуживание элитного уровня ',
    bblock_message4 : 'Свобода путешествий. Вы сами определяете время и маршрут полета бизнес-джета («частного самолета»). Заказать частный рейс можно с отправлением уже через несколько часов с момента оформления заказа ',

    faq_header : 'Что влияет на стоимость частного рейса?',
    faq_message1 : 'Тип самолета, его новизна, дата обновления салона, и вместительность',
    faq_message2 : 'Количество пассажиров - чем больше пассажиров летит, тем больше стоимость их обслуживания на борту и в вип терминалах ',
    faq_message3 : 'Особые условия обслуживания частного рейса в аэропорту (например, загруженность аэропорта в даты проведение мероприятий (форумов), погрузка/разгрузка крупногабаритного багажа со спец техникой и др.) ',
    faq_message4 : 'Время в пути - цена напрямую зависит от расстояния до пункта назначения',
    faq_message5 : 'Возможность подбора попутных рейсов Empty leg, что дает значительную экономию средств при заказе частного рейса',
    faq_message6 : 'Количество дней стоянки до обратного вылета - чем дольше самолет находится на стоянке в аэропорту в режиме ожидания пассажиров, тем дороже выйдет общая стоимость полета',
    faq_caption : 'Список дополнительных услуг и отдельные изменения условий перелета могут значительно изменить его стоимость. Обычно, в общую стоимость аренды не входит оплата дополнительных летных часов, обработка самолета антиобледенительной жидкостью, не запланированная задержка вылета по вине клиентов.',

    spec_items_intl_header : 'Международные перелеты на арендованном бизнес джете',
    spec_items_intl_destination1 : 'Москва — Минск',
    spec_items_intl_price1 : 'от 15 000 €',
    spec_items_intl_link1: 'search/UUWW/UMMS',
    spec_items_intl_destination2 : 'Москва — Баку',
    spec_items_intl_price2 : 'от 55 000 €',
    spec_items_intl_link2: 'search/UUWW/UBBB',
    spec_items_intl_destination3 : 'Москва — Нур-Султан',
    spec_items_intl_price3 : 'от 50 000 €',
    spec_items_intl_link3: 'search/UUWW/UACC',
    spec_items_intl_destination4 : 'Москва — Стамбул',
    spec_items_intl_price4 : 'от 70 000 €',
    spec_items_intl_link4: 'search/UUWW/LTFM',
    spec_items_intl_action : 'Смотреть предложения',
    spec_items_dom_header : 'Региональные перелеты на арендованном бизнес джете',
    spec_items_dom_destination1 : 'Москва — Сочи',
    spec_items_dom_price1 : 'от 55 000 €',
    spec_items_dom_link1: 'search/UUWW/URSS',
    spec_items_dom_destination2 : 'Москва — Санкт-Петербург',
    spec_items_dom_price2 : 'от 14 000 €',
    spec_items_dom_link2: 'search/UUWW/ULLI',
    spec_items_dom_destination3 : 'Москва — Казань',
    spec_items_dom_price3 : 'от 17 000 €',
    spec_items_dom_link3: 'search/UUWW/UWKD',
    spec_items_dom_destination4 : 'Москва — Тюмень',
    spec_items_dom_price4 : 'от 40 000 €',
    spec_items_dom_link4: 'search/UUWW/USTR',
    spec_items_dom_action : 'Смотреть предложения',

    search_results_header : 'Выгодные предложения',
    search_results_caption : 'Особо выгодное предложение от авиакомпаний, которые совершают пустой перелет по вашему маршруту:',

    jet_header : ' IT возможности компании Najet',
    jet_caption : 'NAJET  – это уникальный сервис, который делает выбор частного рейса крайне простым и удобным. Команда профессионалов сделала большую работы, чтобы теперь главные пассажиры частных авиарейсов мгновенно могли получить предложение на частную авиаперевозку, выбрать нужный private jet, забронировать для себя бизнес перелет, и оплатить – и все это быстро, честно и безопасно. NAJET – это будущее бизнес перелетов, потому что простота использования очевидна. Предложенные варианты частных рейсов – результат интеграции с производственными программами ведущих Авиакомпаний бизнес авиации в России.',

    footer_left_text: ' Вокруг каждого частного рейса или private jet крутится большое окружение, свита, которая превозносит private jet, и зря. Многие, уже созревшие бизнесмены, собственники огромных состояний и капиталов хотели бы быть проще, но им не дают – из каждого частного рейса раздувается очень большое пламя страсти по роскоши. Наша компания NAJET, понимая все эти особенности частных авиаперевозок и стремится сделать каждую частную авиаперевозку более доступной и простой в эксплуатации.',
    footer_right_text: 'Организация частного рейса – это всегда интересная и достаточно кропотливая работа. Необходимо предусмотреть много деталей каждой частной авиаперевозки, потому что пассажир каждого частного рейса – это человек с повышенным запросом, с высокими требованиями к сервису, к показателям качества. Именно поэтому каждому частному рейсу всегда уделяется большое внимание менеджерами Сервиса NAJET.',
}

