import { useIntl  } from 'react-intl';

export default function ConvertToInternationalCurrencySystem (labelValue) {

    const intl = useIntl();

    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e+9

        ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2) + intl.messages['price_bn']
        // Six Zeroes for Millions
        : Math.abs(Number(labelValue)) >= 1.0e+6

            ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2) + intl.messages['price_mn']
            // Three Zeroes for Thousands
            : Math.abs(Number(labelValue)) >= 1.0e+3

                ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(2) + intl.messages['price_k']

                : Math.abs(Number(labelValue));

}