import { useIntl, FormattedMessage } from 'react-intl';

import './Restrictions.scss';

export default function Restrictions () {
    const Intl = useIntl();
    return (
        <div className="restrictions-content">
            <div className="restrictions-content-title">
                <FormattedMessage id='restrictions_title' />
            </div>
            <div className="restrictions-content-text"  dangerouslySetInnerHTML={ {__html: Intl.messages['restrictions_text']} } />
        </div>
    );
}
