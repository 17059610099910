export const CHASTNY_PERELET = {
    title : 'Частные авиаперевозки, перелеты. Мгновенное предложение онлайн. NAJET.',
    description : 'Частные авиаперевозки это прелет с максимальным комфортом. Мы предоставляем удобный онлайн сервис бронирования частных авиаперевозок по минимальной цене и с большим выбором самолетов.',
    keywords: 'частные авиаперевозки, частный перелет',

    header : 'Онлайн-сервис заказа частной авиаперевозки',

    bblock_header : 'Некоторые факты о частных авиаперевозках',
    bblock_caption : 'С начала новой истории России крепко вошли понятия, включающие в себя слово «частные» - частная собственность, частная компания, частная жизнь, частный самолет.',
    bblock_message1 : 'Частные авиаперевозки сегодня – это, можно с уверенностью сказать, уже норма жизни. К частным авиаперевозкам относятся и перевозки, которые выполняются на бизнес джетах в интересах одного лица, так и в интересах корпораций.',
    bblock_message2 : 'Так как рынок деловой авиации все еще формируется,  то очень много понятий смешаны, например: корпоративные авиаперевозки и частные авиаперевозки, private jet и corporate jet, деловая авиация и бизнес авиация, и многое другое. И каждый термин верный.',
    bblock_message3 : 'Даже, если глава корпорации летит на бизнес джете на деловую встречу, такую авиаперевозку определенно можно назвать частная авиаперевозка. Перелет на бизнес джете на отдых с семьей также будет считаться частной авиаперевозкой.',
    bblock_message4 : 'Возможно, понятие частная авиаперевозка привязана к понятию private jet. Однако частная перевозка возможна и на большом, многокресельном самолете, когда, допустим, друзья собрались и решили слетать все вместе для отмечания того или иного события. Это тоже частная авиаперевозка.',

    faq_header : 'Что отличает частную авиаперевозку от полета бизнес классом?',
    faq_message1 : 'Заказ бортового питания из лучших ресторанов VIP уровня по вашему меню',
    faq_message2 : 'Предоставление высокоскоростного интернета на борту самолета (в зависимости от технической возможности)',
    faq_message3 : 'Обслуживание пассажиров в VIP-терминалах аэропортов вылета и прибытия',
    faq_message4 : 'Возможность внесения некоторых изменений в расписание и количество пассажиров',
    faq_message5 : 'Возможность подбора попутных рейсов Empty leg, что дает значительную экономию средств на аренде частного самолета',
    faq_message6 : '24 часа в сутки, 7 дней в неделю работа клиентского сервиса для помощи в организации частной авиаперевозки',
    faq_caption : 'Некоторое время назад начали  развиваться так называемые «покресельные перевозки», и это тоже частные авиаперевозки. По сути, покресельная продажа самолета – аналог бизнес класса регулярной авиакомпании, но такие частные рейсы выполняются на бизнес джете из специализированного VIP терминала, сохраняя все преимущества рейсов бизнес авиации и поэтому называются частными авиаперевозками.',

    spec_items_intl_header : 'Международные перелеты на арендованном самолете',
    spec_items_intl_destination1 : 'Москва — Минск',
    spec_items_intl_price1 : 'от 15 000 €',
    spec_items_intl_link1: 'search/UUWW/UMMS',
    spec_items_intl_destination2 : 'Москва — Баку',
    spec_items_intl_price2 : 'от 55 000 €',
    spec_items_intl_link2: 'search/UUWW/UBBB',
    spec_items_intl_destination3 : 'Москва — Нур-Султан',
    spec_items_intl_price3 : 'от 50 000 €',
    spec_items_intl_link3: 'search/UUWW/UACC',
    spec_items_intl_destination4 : 'Москва — Стамбул',
    spec_items_intl_price4 : 'от 70 000 €',
    spec_items_intl_link4: 'search/UUWW/LTFM',
    spec_items_intl_action : 'Смотреть предложения',

    spec_items_dom_header : 'Региональные перелеты на арендованном бизнес джете',
    spec_items_dom_destination1 : 'Москва — Сочи',
    spec_items_dom_price1 : 'от 55 000 €',
    spec_items_dom_link1: 'search/UUWW/URSS',
    spec_items_dom_destination2 : 'Москва — Санкт-Петербург',
    spec_items_dom_price2 : 'от 14 000 €',
    spec_items_dom_link2: 'search/UUWW/ULLI',
    spec_items_dom_destination3 : 'Москва — Казань',
    spec_items_dom_price3 : 'от 17 000 €',
    spec_items_dom_link3: 'search/UUWW/UWKD',
    spec_items_dom_destination4 : 'Москва — Тюмень',
    spec_items_dom_price4 : 'от 40 000 €',
    spec_items_dom_link4: 'search/UUWW/USTR',
    spec_items_dom_action : 'Смотреть предложения',

    search_results_header : 'Выгодные предложения',
    search_results_caption : 'Особо выгодное предложение от авиакомпаний, которые совершают пустой перелет по вашему маршруту:',

    jet_header : 'Частная авиаперевозка или бизнес класс?',
    jet_caption : ' Что такое настоящая свобода путешествий? Независимость от расписания аэропортов или авиаперевозчиков, возможность указать дату и время вашего вылета, получить персональный сервис по высшему классу. Частная авиаперевозка - это выбор современных и успешных путешественников, которые могут позволить себе летать с комфортом. Самый главный плюс частной авиаперевозки – Вы летите только своей компанией или семьей, никого постороннего, что особенно важно в реалиях современного мира с учетом эпидемий и других факторов, угрожающих безопасности Вашему здоровью и здоровью Ваших близких.',

    footer_left_text: 'Пользователи деловой или бизнес авиации для своих целей используют частные авиаперевозки  достаточно часто. Это удобно. К сожалению для многих может показаться, что это чересчур, но на самом деле, для настоящего бизнесмена свой бизнес джет – часть его повседневной жизни, такая же, как, например, и машина, или обед в рабочей день. Частная авиаперевозка – очень нужная и важная особенность современного делового этикета. К сожалению, стоит отметить, что в России из частных авиаперевозок сделали культ, и пока еще этот культ не сместился в сторону обыденности, нормы.',
    footer_right_text: 'Мы создали ИТ инфраструктуру, которая позволяет любому человеку, которому нужна частная авиаперевозка на private jet  может воспользоваться нашим сервисом и заказать частный рейс в пять шагов. Это удивительно просто сейчас, несмотря на все сложности индустрии. Команда профессионалов консолидировала все свои знания и теперь частная авиаперевозка доступна всем без каких либо ограничений. Заказать private jet очень просто через сайт, либо с мобильного приложения. Мы верим, что наши клиенты, заказывая для себе частную авиаперевозку испытают настоящее удовольствие от сервиса, над которым наша команду трудилась больше двух лет.',
}


