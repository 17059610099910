import './GetAirportsList.scss';

import hightliter from "./Hightliter";
import { useIntl } from 'react-intl';

export default function GetAirportsList( props )  {

    const intl = useIntl();
    let airportList = ''; // Отфильтрованный по поисковой фразе список аэропортов с html обвесом
    let airportDivClass;  // Для определения правильного CSS, если в центре добавляем класс middle
    let airportDivClassCounter = 0; // Счетчик для определения правильного CSS
    let searchText; // Поисковая фраза

    function handleSelectedChange(e) {
        props.airportsData.forEach((airport) => {
            if (e.target.id.toString() === airport.icao) { props.handleSelectedChange(airport) }
        })
    }

    if (props.airportsData) {
        if (props.searchText) {
            searchText = props.searchText.toLowerCase()
        } else {
            searchText ='';
        }
        for (let i=0;i<props.airportsData.length;i++) {
            let name = props.airportsData[i].name.toLowerCase();
            let name_rus = props.airportsData[i].name_rus.toLowerCase();
            let city = props.airportsData[i].city.toLowerCase();
            let city_rus = props.airportsData[i].city_rus.toLowerCase();
            let iata = props.airportsData[i].iata.toLowerCase();
            let icao = props.airportsData[i].icao.toLowerCase();
            if (
                name.includes(searchText) ||
                name_rus.includes(searchText) ||
                city.includes(searchText) ||
                city_rus.includes(searchText) ||
                iata.includes(searchText) ||
                icao.includes(searchText)
            ) {
                if ((airportDivClassCounter + 1) % 3 === 0) {
                        airportDivClass = 'airport last';
                    } else {
                        airportDivClass = 'airport';
                    }
                airportDivClassCounter++;
                airportList = airportList +
                    '<div class="' + airportDivClass + '" id="' + props.airportsData[i].icao + '">' +
                    '<div class="container" id="' + props.airportsData[i].icao + '">';
                if (intl.locale === 'en-US') {
                    let name = hightliter(props.airportsData[i].name,props.searchText);
                    let city = hightliter(props.airportsData[i].city,props.searchText);
                    airportList = airportList +
                        '<div class="name" id="' + props.airportsData[i].icao + '">' + name +'</div>' +
                        '<div class="location" id="' + props.airportsData[i].icao + '">' + city +'</div>';
                } else {
                    let name_rus = hightliter(props.airportsData[i].name_rus,props.searchText);
                    let city_rus = hightliter(props.airportsData[i].city_rus,props.searchText);
                    airportList = airportList +
                        '<div class="name" id="' + props.airportsData[i].icao + '">' + name_rus +'</div>' +
                        '<div class="location" id="' + props.airportsData[i].icao + '">' + city_rus +'</div>';
                }
                airportList = airportList +
                    '</div>' +
                    '<div class="iata" id="' + props.airportsData[i].icao + '">' + props.airportsData[i].iata +'</div>' +
                    '<div class="icao" id="' + props.airportsData[i].icao + '">' + props.airportsData[i].icao + '</div>' +
                    '</div>';
            }
        }
    }

    return (
        <div className="results"
             onClick={ handleSelectedChange }
             dangerouslySetInnerHTML={ {__html: airportList} }
        />
    );
}
