import './Book.scss';

import { PATH } from "../config";

import { useIntl, FormattedMessage } from 'react-intl';
import { useEffect, useState } from "react";

import ym from 'react-yandex-metrika';

import IsPhoneValid from "./IsPhoneValid";
import IsMailValid from "./IsMailValid";

import completePic from '../assets/complele.svg';

const termLinks = require('../termLinks.json');

export default function Book( props )  {

    const [formState, setFormState] = useState('registration');
    const [isFormValid, setIsFormValid] = useState(false);
    const [confirmationRepeated, setConfirmationRepeated] = useState(false);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [code, setCode] = useState('');

    const Intl = useIntl();

    let compensationsLink = '';
    let flightsLink = '';

    if (props.flightInfo?.aircraft) {
        compensationsLink = termLinks[props.flightInfo.aircraft]?.compensations || '';
        flightsLink = termLinks[props.flightInfo.aircraft]?.flights || '';
    }

    useEffect(() => {
        ym('reachGoal','BOOKING_FORM');
    }, []);

    useEffect(() => {
        if (
            IsMailValid(email) &&
            IsPhoneValid(phone) &&
            name !== '' &&
            acceptTerms === true
        ) {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }
    }, [name, email, phone, acceptTerms]);

    useEffect(() => {
        if ( code.length > 3 ) {
            let requestParams = {
                code: code,
                phone: phone,
            };
            let url = PATH.CONFIRM_SMS;
            fetch( url, { method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },body: JSON.stringify(requestParams),} )
                .then(response => response.json())
                .then(
                    (result) => {
                        sendPreOrder( result.access_token );
                        setFormState('confirmed')
                    },
                    (error) => {  console.log(error)});
        }
    }, [code]);

    useEffect(() => {
        if ( confirmationRepeated ) {
            sendBookRequest({
                name : name,
                email : email,
                phone : phone,
                isFormValid : isFormValid,
            });
        }
    }, [confirmationRepeated]);

    function sendBookRequest( props ){
        if ( props.isFormValid ) {
            let url = new URL( PATH.LOGIN_API );
            let requestParams = {};
            requestParams.phone = props.phone;
            requestParams.name = props.name;
            requestParams.email = props.email;
            requestParams.url = window.location.href;
            requestParams.its_legal = true;
            fetch( url, { method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },body: JSON.stringify(requestParams),} )
                .then(response => response.json())
                .then(
                    () => { setFormState('confirmation') },
                    (error) => {  console.log(error)});
        }
    }

    function sendPreOrder( access_token ){
        let url = new URL( PATH.PRE_ORDERS );
        let requestParams = {};
        requestParams.access_token = access_token;
        requestParams.card_id = props.cardId;
        fetch( url, { method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer ' + access_token,
            },body: JSON.stringify(requestParams),} )
            .then(response => response.json())
            .then(
                () => {},
                (error) => {  console.log(error)});
    }

    return (
        <div className="book">
            <div className="modal-window" onClick={() => props.isBookOpenToggle(false) }/>
            { formState === 'registration' &&
                <div className="book-form">
                    <div className="book-form-header"><FormattedMessage id='book_header'/></div>
                    <div className="book-form-input-caption"><FormattedMessage id='book_name'/></div>
                    <input className="book-form-input"
                           placeholder={ Intl.messages[ 'name_example' ] }
                           value={ name }
                           onChange={ (e) => setName( e.target.value ) }
                    />
                    <div className="book-form-input-caption"><FormattedMessage id='book_email'/></div>
                    <input className="book-form-input"
                           placeholder={ Intl.messages[ 'email_example' ] }
                           value={ email }
                           onChange={ (e) => setEmail( e.target.value ) }
                    />
                    <div className="book-form-input-caption"><FormattedMessage id='book_phone'/></div>
                    <input className="book-form-input"
                           placeholder={ Intl.messages[ 'telephone_number' ] }
                           value={ phone }
                           onChange={ (e) => setPhone( e.target.value )  }
                    />
                    <div className="book-form-text"><FormattedMessage id='book_text'/></div>
                    <div className="book-form-rules">
                        <input type="checkbox" onChange={ (event) => setAcceptTerms(event.target.checked) } checked={acceptTerms} />
                        <div>
                            Я ознакомлен(а) и принимаю&nbsp;
                            <a href="/docs/politics.pdf" target="_blank">Условия использования персональных данных</a>,&nbsp;
                            <a href="/docs/rules.pdf" target="_blank">Правила Сервиса NAJET</a>,&nbsp; 
                            <a href={compensationsLink} target="_blank">Компенсации за отказ от перевозки</a>&nbsp; 
                            и&nbsp;
                            <a href={flightsLink} target="_blank">Правила перелета пассажиров Авиакомпании</a>.
                        </div>
                    </div>
                    <div className={ isFormValid ? 'book-form-button book-form-button-active' : 'book-form-button' }
                         onClick={ () => sendBookRequest({
                             name : name,
                             email : email,
                             phone : phone,
                             isFormValid : isFormValid,
                         }) }
                    ><span><FormattedMessage id='book_button'/></span></div>
                </div>
            }
            { formState === 'confirmation' &&
                <div className="book-form">
                    <div className="book-form-header"><FormattedMessage id='book_confirm'/></div>
                    <div className="book-form-text"><FormattedMessage id='book_confirm_text'/></div>
                    <div className="book-form-input-caption"><FormattedMessage id='book_confirm_code'/></div>
                    <input className="book-form-input"
                           value={ code }
                           onChange={ (e) => setCode( e.target.value )  }
                    />
                    <div className="book-form-text-confirm">
                        <span><FormattedMessage id='book_confirm_repeat1'/>&nbsp;</span>
                        <span>
                            { confirmationRepeated
                                ? <FormattedMessage id='book_confirm_repeat2'/>
                                : <b onClick={() => setConfirmationRepeated(true) }><FormattedMessage id='book_confirm_repeat2'/></b>
                            }
                        </span>
                    </div>
                </div>
            }
            { formState === 'confirmed' &&
                <div className="book-form">
                    <div className="book-form-image"><img src={ completePic } /></div>
                    <div className="book-form-header"><FormattedMessage id='book_complete'/></div>
                    <div className="book-form-text-confirm"><FormattedMessage id='book_complete_text'/></div>
                </div>
            }
        </div>
    );
}
