export const PRIVATE_JET= {
    title : 'Private jet. Мгновенное предложение онлайн. NAJET.',
    description : 'NAJET делает все возможное, чтобы Private Jet стал еще более доступным, а заказ Private Jet стал бы максимально простым. Стоимость, удобный сервис бронирования и заказа Private Jet.',
    keywords: 'private jet',

    header : 'Онлайн-сервис заказа и аренды Private Jet',

    bblock_header : 'Преимущества аренды Private Jet',
    bblock_caption : 'Арендуя Private Jet, человек приобретает следующие условия в полёте:',
    bblock_message1 : 'Подбор Private Jet под ваши запросы. Заказчик получает предложения на все Private Jet, которые могут выполнить заявленный перелет. Заказчик сам выбирает Jet который подходит ему по всем параметрам, включая вместимость, дальность полета, оборудование и комфортабельность салона, класс обслуживания и многое другое',
    bblock_message2 : 'Высокий уровень безопасности. Флот деловой авиации комплектуется высококлассными воздушными судами, а пилоты проходят строгий отбор и периодическое повышение квалификации. Использование бизнес джета, как и Private Jet это залог абсолютной безопасности',
    bblock_message3 : 'Приватность и комфорт. На борту Private Jet нет посторонних пассажиров, оборудование салона обеспечивает максимально комфортные условия, во время перелета клиентам предлагается обслуживание элитного уровня ',
    bblock_message4 : 'Свобода путешествий. Вы сами определяете время и маршрут полета Private Jet. Заказать Private Jet можно с отправлением уже через несколько часов с момента оформления заказа ',

    faq_header : 'Разнообразие типов Private Jet',
    faq_message1 : 'Легкие турбовинтовые (4-6 пассажиров). Предназначены для полетов на расстояния до 1300 км со скоростью до 400 км/час.',
    faq_message2 : 'Легкие реактивные(4-9 пассажиров). Летают со скоростью до 800 км/час на расстояние до 2000 км.',
    faq_message3 : 'Средние реактивные (8-9 пассажиров). Преодолевают расстояния до 3000 км со скоростью до 900 км/ч. Отличаются более просторным и комфортабельным салоном',
    faq_message4 : 'Суперсредние реактивные (10-12  пассажиров). Способны летать на расстояния до 7000 км, развивают крейсерскую скорость до 900 км/ч',
    faq_message5 : 'Реактивные дальнемагистральные (9-19 пассажиров). Пролетают до 10000 км и более. Салон такого Private Jet отличается повышенной комфортабельностью, имеет спальные места, зону отдыха и другие зоны.',
    faq_message6 : 'Есть и большие Private Jet (типа ТУ-204, Boeing 757 и др), салон которых превращен в роскошные апартаменты VIP класса',
    faq_caption : 'Несмотря на то, что наши бизнесмены предпочитают самолеты иностранного производства, российские авиапроизводители также представлены в этом сегменте: Sukhoi Super Jet в компоновке Busines jet, Ту-204 в бизнес компоновке, и, конечно же Boeing 757. Все эти типы самолетов смело можно отнести к  Private Jet.',

    spec_items_intl_header : 'Международные перелеты на арендованном бизнес джете',
    spec_items_intl_destination1 : 'Москва — Минск',
    spec_items_intl_price1 : 'от 15 000 €',
    spec_items_intl_link1: 'search/UUWW/UMMS',
    spec_items_intl_destination2 : 'Москва — Баку',
    spec_items_intl_price2 : 'от 55 000 €',
    spec_items_intl_link2: 'search/UUWW/UBBB',
    spec_items_intl_destination3 : 'Москва — Нур-Султан',
    spec_items_intl_price3 : 'от 50 000 €',
    spec_items_intl_link3: 'search/UUWW/UACC',
    spec_items_intl_destination4 : 'Москва — Стамбул',
    spec_items_intl_price4 : 'от 70 000 €',
    spec_items_intl_link4: 'search/UUWW/LTFM',
    spec_items_intl_action : 'Смотреть предложения',
    spec_items_dom_header : 'Региональные перелеты на арендованном бизнес джете',
    spec_items_dom_destination1 : 'Москва — Сочи',
    spec_items_dom_price1 : 'от 55 000 €',
    spec_items_dom_link1: 'search/UUWW/URSS',
    spec_items_dom_destination2 : 'Москва — Санкт-Петербург',
    spec_items_dom_price2 : 'от 14 000 €',
    spec_items_dom_link2: 'search/UUWW/ULLI',
    spec_items_dom_destination3 : 'Москва — Казань',
    spec_items_dom_price3 : 'от 17 000 €',
    spec_items_dom_link3: 'search/UUWW/UWKD',
    spec_items_dom_destination4 : 'Москва — Тюмень',
    spec_items_dom_price4 : 'от 40 000 €',
    spec_items_dom_link4: 'search/UUWW/USTR',
    spec_items_dom_action : 'Смотреть предложения',

    search_results_header : 'Выгодные предложения',
    search_results_caption : 'Особо выгодное предложение от авиакомпаний, которые совершают пустой перелет по вашему маршруту:',

    jet_header : 'Какие факторы влияют на стоимость аренды Private Jet',
    jet_caption : 'Стоимость заказа Private Jet  отличается в зависимости от пассажировместимости, дальности полета, особенностей конструкции, комфортабельности салона и даты его обновления. Как правило, чем больше джет, тем выше стоимость летного часа. Также на стоимость перелета влияют и такие факторы как: количество пассажиров на заяленный рейс (чем больше пассажиров летит, тем больше стоимость их обслуживания на борту и в вип терминалах), возможность подбора попутных рейсов Empty leg, что дает значительную экономию средств при заказе Private Jet,  количество дней стоянки до обратного вылета (чем дольше самолет находится на стоянке в аэропорту в режиме ожидания пассажиров, тем дороже выйдет общая стоимость полета). Ну и конечно же основной фактор, влияющий на стоимость перелета на Private Jet - время в пути, т.к. цена напрямую зависит от расстояния до пункта назначения',

    footer_left_text: 'На Private Jet действительно безопасно и надёжно летать. Производитель и авиакомпания на протяжении всего жизненного цикла самолета проводят на На Private Jet специальные тесты, организуют техническое обслуживание и доработку систем. Private Jet всегда пребывают в идеальном состоянии. Авиакомпании – партнеры Сервиса NAJET – самые надежные и проверенные перевозчики в стране, имеющие все необходимые лицензии и сертификаты, подтверждающие безопасность эксплуатируемых Private Jet.',
    footer_right_text: 'Услуга NAJET достаточно проста в использовании. Для бронирования и заказа рейса достаточно перейти на сайт и выбрать нужный Private Jet. На нашей платформе NAJET представлены фотографии и технические характеристики всех Private Jet, которые доступны для заказа, бронирования  и перелета. То есть вы получите необходимую информацию о возможных для аренды Private Jet в течение секунд без всяких дополнительных регистраций. Cервис аренды Private Jet NAJET позволяет получать мгновенное предложение с ценой и фото самолета онлайн.',
}
