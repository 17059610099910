import { useIntl, FormattedMessage } from 'react-intl';
import { useState } from "react";

import './FleetFilters.scss';

import pax_pic from '../assets/fleet-sort-pax.png';
import pax_pic_active from '../assets/fleet-sort-pax-active.png';
import year_pic from '../assets/fleet-sort-year.png';
import year_pic_active from '../assets/fleet-sort-year-active.png';
import name_pic from '../assets/fleet-sort-name.png';
import name_pic_active from '../assets/fleet-sort-name-active.png';

export default function FleetFilters ( props ) {

    const [fleetFilter, setFleetFilter] = useState('');

    let currentPaxPic = pax_pic;
    let currentYearPic = year_pic;
    let currentNamePic = name_pic;

    const Intl = useIntl();

    function fleetDataYearSort () {
        let sortedFleetData = props.fleetData.slice();
        sortedFleetData.sort((prev, next) => next.year_of_creation - prev.year_of_creation );
        props.setFleetData( sortedFleetData );
        setFleetFilter('year');
    }

    function fleetDataPaxSort () {
        let sortedFleetData = props.fleetData.slice();
        sortedFleetData.sort((prev, next) => next.max_pax - prev.max_pax);
        props.setFleetData( sortedFleetData );
        setFleetFilter('max_pax');
    }

    function fleetDataNameSort () {
        let sortedFleetData = props.fleetData.slice();
        sortedFleetData.sort((prev, next) => {
            let typePrev = prev.type.toLowerCase()
            let typeNext = next.type.toLowerCase()
            if (typePrev < typeNext)
                return -1
            if (typePrev > typeNext)
                return 0
        });
        props.setFleetData( sortedFleetData );
        setFleetFilter('name');
    }

    if ( fleetFilter === 'name' ) {
        currentNamePic = name_pic_active;
    }

    if ( fleetFilter === 'year' ) {
        currentYearPic = year_pic_active;
    }

    if ( fleetFilter === 'max_pax' ) {
        currentPaxPic = pax_pic_active;
    }

    return (
        <div className="fleet-filters">
            <div className="fleet-filters-line" />
            <div className="fleet-filters-wrapper">
                <div className="fleet-header"><FormattedMessage id='fleet_header' /></div>
                <div className="fleet-header-buttons">
                    <div><FormattedMessage id='sort' />&nbsp;</div>
                    <div><img src={ currentNamePic } onClick={ fleetDataNameSort } alt={ Intl.messages['name_sort'] } /></div>
                    <div><img src={ currentYearPic } onClick={ fleetDataYearSort } alt={ Intl.messages['year_sort'] } /></div>
                    <div><img src={ currentPaxPic } onClick={ fleetDataPaxSort} alt={ Intl.messages['pax_sort'] } /></div>
                </div>
            </div>
        </div>
    );
}
