import './PaxButton.scss';
import { FormattedMessage } from "react-intl";

export default function PaxButton ( props ) {
 return (
        <div className="pax-button">
            <div className="pax-button-item-long-label"><FormattedMessage id='pax' values={{ count: props.pax }}/></div>
            <div className="pax-button-item-short-label"><FormattedMessage id='pax_short' values={{ count: props.pax }}/></div>
            <div className="pax-button-item">
                <div className="pax-button-caption pax-button-more" onClick={ props.setMorePax }>+</div>
                <div className="pax-button-tilde" />
                <div className="pax-button-caption pax-button-less" onClick={ props.setLessPax }>-</div>
            </div>
        </div>
    );
}