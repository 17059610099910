import { useIntl, FormattedMessage } from 'react-intl';

import './ContactsContent.scss';

import telpic from '../assets/telephone.png';
import mailpic from '../assets/mail-contacts.png';
import adresspic from '../assets/adress.png';

export default function ContactsContent () {
    const Intl = useIntl();
    return (
        <div className="contacts-content">
            <div className="contacts-block">
                <div className="contacts-label">
                    <span><a href="tel:+7(495)120-80-20">+7(495)120-80-20</a></span>
                    <span><a href="tel:+7(495)120-80-20"><img src={ telpic } alt={ Intl.messages['telephone'] } /></a></span>
                 </div>
                <div className="contacts-label">
                    <span><a href="mailto:info@najet.ru">info@najet.ru</a></span>
                    <span><a href="mailto:info@najet.ru"><img src={ mailpic } alt={ Intl.messages['mail'] } /></a></span>
                </div>
                <div className="contacts-label"><FormattedMessage id='adress1' /></div>
                <div className="contacts-label secondline">
                    <span><a href="https://yandex.ru/maps/-/CCUF6CaSSB"><FormattedMessage id='adress2' /></a></span>
                    <span><a href="https://yandex.ru/maps/-/CCUF6CaSSB"><img src={ adresspic } alt={ Intl.messages['adress'] } /></a></span>
                </div>
                <div className="contacts-helpdesk"><FormattedMessage id='clients_help' /></div>
                <div className="contacts-label secondline">
                    <span><a href="mailto:client-service@najet.ru">client-service@najet.ru</a></span>
                    <span><a href="mailto:client-service@najet.ru"><img src={ mailpic } alt={ Intl.messages['mail'] } /></a></span>
                </div>
                <div className="contacts-helpdesk"><FormattedMessage id='tech_help' /></div>
                <div className="contacts-label secondline">
                    <span><a href="mailto:tech-support@najet.ru">tech-support@najet.ru</a></span>
                    <span><a href="mailto:tech-support@najet.ru"><img src={ mailpic } alt={ Intl.messages['mail'] } /></a></span>
                </div>
            </div>
        </div>
    );
}
