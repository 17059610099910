import { useIntl, FormattedMessage } from 'react-intl';

import './PartnersContent.scss';
import { useEffect, useState } from "react";

import IsMailValid from "./IsMailValid";
import IsPhoneValid from "./IsPhoneValid";

import { PATH } from "../config";


export default function PartnersContent () {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const [isFormValid, setIsFormValid] = useState(false);
    const [isFormSent, setIsFormSent] = useState(false);

    const Intl = useIntl();

    useEffect(() => {
        if (
            ( IsMailValid(email) || IsPhoneValid(email) )&&
            name !== '' &&
            message !== ''
        ) {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }
    }, [name, email, message]);

    function sendPartnerRequest () {
        if ( isFormValid && !isFormSent) {
            let url = new URL( PATH.PARTNER_EMAIL );
            let requestParams = {
                name: name,
                contact: email,
                message: message,
            };
            fetch( url, { method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },body: JSON.stringify(requestParams),} )
                .then(response => response.json())
                .then(
                    (result) => {
                        console.log(result);
                        setIsFormSent(true);
                    },
                    (error) => { console.log(error) });
        }
    }

    return (
        <div className="partners-content">
            <div className="partners-content-title"><FormattedMessage id='partners_content_title' /></div>
            <div className="partners-content-text" dangerouslySetInnerHTML={ {__html: Intl.messages['partners_content_text']} } />
            <form>
                <div className="partners-content-form-title"><FormattedMessage id='partners_content_form_title' /></div>
                <div className="partners-content-form-caption"><FormattedMessage id='partners_content_form_caption' /></div>
                <div className="partners-content-form-input-title"><FormattedMessage id='partners_content_form_input_name' /></div>
                <input
                    placeholder={ Intl.messages['name_example'] }
                    value={ name }
                    onChange={ (e) => setName( e.target.value )}
                />
                <div className="partners-content-form-input-title"><FormattedMessage id='partners_content_form_input_email' /></div>
                <input
                    placeholder={ Intl.messages['email_example'] }
                    value={ email }
                    onChange={ (e) => setEmail( e.target.value )}
                />
                <div className="partners-content-form-input-title"><FormattedMessage id='partners_content_form_input_message' /></div>
                <input
                    placeholder={ Intl.messages['message_example'] }
                    value={ message }
                    onChange={ (e) => setMessage( e.target.value )}
                />
                <div className={ isFormValid && !isFormSent ? 'partners-content-form-button partners-content-form-button-active' : 'partners-content-form-button' }
                     onClick={ () => sendPartnerRequest() }>
                    <span><FormattedMessage id='partners_content_form_input_submith' /></span>
                </div>
            </form>
        </div>
    );
}
