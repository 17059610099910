import { HelmetProvider, Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import { useIntl } from 'react-intl';

import Header from '../components/Header';
import FirstScreen from "../components/FirstScreen";
import Footer from "../components/Footer";

import GetAirportsData from "../components/GetAirportsData";

export default function Home () {
    const [airportsData, setAirportsData] = useState(null);
    const intl = useIntl();

    useEffect(() => {
        GetAirportsData( setAirportsData );
    }, [])

    return (
        <HelmetProvider>
            <Helmet>
                <title>{ intl.messages['home_title'] }</title>
                <meta name="og:title" content={ intl.messages['home_title'] }/>
                <meta name="description" content={ intl.messages['home_description'] } />
                <meta name="og:description" content={ intl.messages['home_description'] }/>
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href={ 'https://najet.online' + window.location.pathname } />
            </Helmet>
            <Header />
            <FirstScreen
                airportsData = { airportsData }
            />
            <Footer />
        </HelmetProvider>
    );
}

