import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";

export default function hightliter(input, query)
{
    const matches = match(input, query)
    const parts = parse(input, matches)
    const result = parts.map(
        part => (
            part.highlight ? `<b>${ part.text }</b>` : part.text)
    )

    return result.join('');
}