import './DataPicker.scss';
import './Calendar.css'

import Calendar from "react-calendar";

import React, { useEffect , useState } from "react";
import { useIntl, FormattedMessage } from 'react-intl';

import { SETTINGS } from "../config";
import GetFirstRequestDate from "./GetFirstRequestDate";

import calendarIconInactive from '../assets/calendar-icon-inactive.svg';
import calendarIconActive from '../assets/calendar-icon-active.svg';

export function SetDataPickerOffset(props) {
    let dateItemMobWidth = 87; // Длинна плашки с датой для предварительной промотки в мобиле
    let dateItemDeskWidth = 77; // Длинна плашки с датой для предварительной промотки в десктопе
    let timeItemWidth = 87; // Длинна плашки с временем для предварительной промотки
    let baseDeskOffset = 4; // Если десктоп, по отставляем указанное количество позиций слева
    let dateCounter;
    let departureDate;
    let departureBackDate;
    let i;

    if (props.departureDate) {
        dateCounter = GetFirstRequestDate(new Date);
        departureDate = new Date(props.departureDate);
        for (i=0; dateCounter.toDateString() !== departureDate.toDateString(); i++) { dateCounter.setDate(dateCounter.getDate() + 1); }
        if ( props.desktop ) {
            document.getElementById("date_div").scrollLeft = dateItemDeskWidth * (i - baseDeskOffset);
        } else {
            document.getElementById("date_div").scrollLeft = dateItemMobWidth * i;
            document.getElementById("time_div").scrollLeft = timeItemWidth * departureDate.getHours();
        }
    }

    if (props.departureBackDate) {
        dateCounter = GetFirstRequestDate(new Date);
        departureBackDate = new Date(props.departureBackDate);
        for (i=0; dateCounter.toDateString() !== departureBackDate.toDateString(); i++) { dateCounter.setDate(dateCounter.getDate() + 1); }
        if ( props.desktop ) {
            document.getElementById("date_back_div").scrollLeft = dateItemDeskWidth * (i - baseDeskOffset);
        } else {
            document.getElementById("date_back_div").scrollLeft = dateItemMobWidth * i;
            document.getElementById("time_back_div").scrollLeft = timeItemWidth * departureBackDate.getHours();
        }
    }
}

export default function DataPicker (props)  {
    const [isCalendarOpened, setIsCalendarOpened] = useState(false);

    let itemsDepth = SETTINGS.ORDER_DEPTH_DAYS; // Количество ячеек с датой от даты ближайшего заказа
    let timeItemWidth = 87; // Длинна плашки с временем для предварительной промотки

    let dataPickerLastDate = GetFirstRequestDate(new Date);
    dataPickerLastDate.setDate(dataPickerLastDate.getDate() + itemsDepth - 1);

    let dateItems = generateDateItems ();
    let timeItems = generateTimeItems ();

    const Intl = useIntl();

    useEffect(() => {
        let firstDate = GetFirstRequestDate(new Date);
        document.getElementById("time_div").scrollLeft = timeItemWidth * firstDate.getHours();
    }, []);

    function departureDateChangeHandler(e, date, setDate) {
        let datePicked = GetFirstRequestDate(new Date);
        datePicked.setHours(date.getHours());
        datePicked.setDate(e.target.id);
        setDate(datePicked);
    }

    function calendarDepartureDateChangeHandler(value) {
        let datePicked;
        let setDate;
        let elementToScroll;
        let dayCouter;                                   // Счетчик для вычисления количества дней, которые нужно проскролить
        let firstDate = GetFirstRequestDate(new Date); // Высчитываем и устанавливаем прокрутку табло дней
        if (props.whichDateChoosing === 'back') {
            datePicked = new Date( props.departureBackDate );
            setDate = props.setDepartureBackDate;
            elementToScroll = document.getElementById("date_back_div");
        } else {
            datePicked = new Date( props.departureDate );
            setDate = props.setDepartureDate;
            elementToScroll = document.getElementById("date_div");
        }
        datePicked.setDate(value.getDate());
        datePicked.setMonth(value.getMonth());
        datePicked.setFullYear(value.getFullYear());
        setDate(datePicked);
        for (dayCouter = 0;firstDate.toDateString() !== datePicked.toDateString();dayCouter++) { firstDate.setDate(firstDate.getDate()+1); }
        if ( dayCouter > 5 ) { elementToScroll.scrollLeft = 77 * (dayCouter - 4); } else { elementToScroll.scrollLeft = 0; }
        setIsCalendarOpened( !isCalendarOpened ); // Закрываем календарь
    }

    function generateDateItems () {
        let itemDate = GetFirstRequestDate(new Date);
        let dateItems = [];
        for (let i=0;i<itemsDepth;i++) {
            let dataItem = {};
            dataItem.index = i;
            dataItem.id = i + GetFirstRequestDate(new Date).getDate();
            dataItem.day = itemDate.toLocaleString("ru", { day: 'numeric' });
            dataItem.month = itemDate.toLocaleString("ru", { month: 'short' }).substring(0,3);
            dataItem.date = new Date(itemDate);
            if (i === 0) { dataItem.weekday = 'завтра'; } else { dataItem.weekday = itemDate.toLocaleString("ru", { weekday: 'short' }); }
            dateItems.push(dataItem);
            itemDate.setDate(itemDate.getDate()+1);
        }
        return dateItems;
    }

    function RenderDateItem ( props ) {
        let picked = props.item.date.toDateString() === props.date.toDateString();
        return (
            <div className={ picked ? 'datapicker-date-item datapicker-date-item-picked' : 'datapicker-date-item' } onClick={ (e) => departureDateChangeHandler(e, props.date, props.setDate) } id={ props.item.id }>
                <div className="datapicker-date-item-data" id={ props.item.id }>{ props.item.day}&nbsp;{ props.item.month }</div>
                <div className="datapicker-date-item-details" id={ props.item.id }>{ props.item.weekday }</div>
            </div>
        );
    }

    function departureTimeChangeHandler(e, date, setDate) {
        let datePicked = new Date(date);
        datePicked.setHours(e.target.id);
        setDate(datePicked);
    }

    function generateTimeItems () {
        let itemDate = GetFirstRequestDate(new Date);
        itemDate.setHours(0)
        let timeItems = [];
        for (let i=0;i<24;i++) {
            let timeItem = [];
            timeItem.index = i;
            timeItem.time = itemDate.toLocaleString("ru", { hour: '2-digit' }) + ':00';
            timeItems.push(timeItem);
            itemDate.setHours(itemDate.getHours()+1);
        }
        return timeItems;
    }

    function RenderTimeItem ( props ) {
        let picked = props.item.time === props.date.toLocaleString("ru", { hour: '2-digit' }) + ':00';
        return (
            <div className={ picked ? 'datapicker-date-item datapicker-date-item-picked' : 'datapicker-date-item' } onClick={ (e) => departureTimeChangeHandler(e, props.date, props.setDate) } id={ props.item.index }>
                <div className="datapicker-date-item-data datapicker-time-item-data" id={ props.item.index }>{ props.item.time }</div>
                <div className="datapicker-date-item-details datapicker-time-item-details" id={ props.item.index }>→ { props.averageFlightTime ? props.averageFlightTime : '' }</div>
            </div>
        );
    }

    //  Оператор включения редактирования обратной даты. Включаем, по клику из панели в десктопе, включено "Обрытный рейс" в мобиле
    let editBackDepartureDate;
    if (window.innerWidth > 700) {
        editBackDepartureDate = props.whichDateChoosing === 'back';
    } else {
        editBackDepartureDate = props.departureBackDate !== null;
    }

    return (
        <div className={ props.whichDateChoosing? 'datapicker' : 'datapicker datapicker-inactive' }>
            <div className={ isCalendarOpened ? 'datapicker-calendar datapicker-calendar-active' : 'datapicker-calendar datapicker-calendar-inactive'}>
                <Calendar
                    className ="datapicker-calendar-inactive-content"
                    minDetail = "month"
                    minDate = { GetFirstRequestDate(new Date) }
                    maxDate = { dataPickerLastDate }
                    value = { props.whichDateChoosing === 'back' ? props.departureBackDate : props.departureDate }
                    onChange = { ( value ) => calendarDepartureDateChangeHandler( value ) }
                />
                <div><img onClick={ () => setIsCalendarOpened(!isCalendarOpened) } src={ isCalendarOpened ? calendarIconActive : calendarIconInactive }  alt={ Intl.messages['calendar'] } /></div>
            </div>
            <div className="datapicker-label"><FormattedMessage id='departure_date' /></div>
            { props.departureBackDate &&
                <div className="datapicker-direction">
                    <div className="datapicker-direction-item"><FormattedMessage id='there' /></div>
                    <div className="datapicker-direction-item datapicker-direction-item-back"><FormattedMessage id='back' /></div>
                </div>
            }
            <div className={ !editBackDepartureDate ? 'datapicker-date' : 'datapicker-date datapicker-date-inactive'} id="date_div">
                { dateItems.map(item => (
                    <RenderDateItem
                        key = { item.index }
                        item = { item }
                        date = { props.departureDate }
                        setDate = { props.setDepartureDate }
                    />
                )) }
            </div>
            { props.departureBackDate &&
                <div className={ props.whichDateChoosing === 'back' ? 'datapicker-date' : 'datapicker-date  datapicker-date-back datapicker-date-inactive'} id="date_back_div">
                    { dateItems.map(item => (
                        <RenderDateItem
                            key = { item.index }
                            item = { item }
                            date = { props.departureBackDate }
                            setDate = { props.setDepartureBackDate }
                        />
                    )) }
                </div>
            }
            <div className="datapicker-label datapicker-time-label"><FormattedMessage id='departure_time' /></div>
            <div className={ !editBackDepartureDate ? 'datapicker-date datapicker-time' : 'datapicker-date datapicker-time datapicker-date-inactive'} id="time_div">
                { timeItems.map(item => (
                    <RenderTimeItem
                        key = { item.index }
                        item = { item }
                        date = { props.departureDate }
                        setDate = { props.setDepartureDate }
                        averageFlightTime = { props.averageFlightTime?.there.toLocaleString("ru", {  hour: 'numeric', minute: 'numeric'}) }
                    />
                )) }
            </div>
            { props.departureBackDate &&
                <div className={ props.whichDateChoosing === 'back' ? 'datapicker-date datapicker-time' : 'datapicker-date datapicker-date-back datapicker-time datapicker-date-inactive'}  id="time_back_div">
                    { timeItems.map(item => (
                        <RenderTimeItem
                            key = { item.index }
                            item = { item }
                            date = { props.departureBackDate }
                            setDate = { props.setDepartureBackDate }
                            averageFlightTime = { props.averageFlightTime?.back.toLocaleString("ru", {  hour: 'numeric', minute: 'numeric'}) }
                        />
                    )) }
                </div>
            }
            <div className="datapicker-button" onClick={ () => props.setWhichDateChoosing(null) }>
                <div className="datapicker-button-label">Применить</div>
            </div>
        </div>
    );
}