import './SeoSpecItems.scss';

import { useIntl } from 'react-intl';
import { Link } from "react-router-dom";

import isPreRendering from "./IsPreRendering";

import left_arrow from "../assets/left-arrow.svg";
import right_arrow from "../assets/right-arrow.svg";

export default function SeoSpecItems(props)  {

    const Intl = useIntl();
    const wrapperID = 'seo-spec-items-wrapper-' + props.index;

    function RightHandler() {
        document.getElementById(wrapperID).scrollBy(50,0);
    }

    function LeftHandler() {
        document.getElementById(wrapperID).scrollBy(-50,0);
    }

    function RenderSpecSeoItem(props) {
        return (
            <div className="seo-spec-item-wrapper" key={ props.index }>
                <div className="seo-spec-item">
                    <img src={ props.item.planeImage } alt={ props.item.planeType } />
                    <div className="seo-spec-item-type">{ props.item.planeType }</div>
                    <div className="seo-spec-item-destination">{ props.item.destination }</div>
                    <div className="seo-spec-item-price">{ props.item.price }</div>
                    { !isPreRendering() &&
                        <Link to={ Intl.messages[ 'currentLocaleLink' ] + props.item.link }>
                            <div className="seo-spec-item-button">
                                <span>{ props.item.action }</span></div>
                        </Link>
                    }
                </div>
            </div>
        );
    }

    return (
        <div className="seo-spec-items">
            <div className="seo-spec-top">
                <div className="seo-spec-header">{ props.header }</div>
                <div className="seo-spec-navigation">
                    <img src={ left_arrow } onClick={ LeftHandler } alt={ Intl.messages['left'] } />&nbsp;
                    <img src={ right_arrow } onClick={ RightHandler } alt={ Intl.messages['right'] } />
                </div>
            </div>
            <div className="seo-spec-items-wrapper" id={ wrapperID }>
                { props.flights.map((item, index) => (
                    <RenderSpecSeoItem
                        item = { item }
                        key = { index }
                    />
                ))}
            </div>
        </div>
    );
}