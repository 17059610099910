export const ZAKAZ_CHARTERA = {
    title : 'Заказа чартера. Мгновенное предложение онлайн. NAJET.',
    description : 'Заказывайте чартеры по России и за границу с NAJET. Групповые перевозки на самолетах бизнес авиации. Стоимость, цена, удобный сервис бронирования чартерных рейсов на самолетах бизнес авиации.',
    keywords: 'заказ чартера',

    header : 'Онлайн-сервис заказа чартерных рейсов',

    bblock_header : 'Что такое чартерный рейс?',
    bblock_caption : 'Чартерный рейс на самолетах бизнес авиации – самый лучший вариант для групповых и корпоративных перевозок сотрудников вашей компании в командировки, и на деловые мероприятия, который имеет ряд преимуществ:',
    bblock_message1 : ' Маршрут перелета, время вылета, организация обслуживания на борту составляется на основе вашего запроса.',
    bblock_message2 : ' Обслуживание пассажиров в отдельных терминалах, бизнес залах или на отдельных стойках регистрации.',
    bblock_message3 : ' Чартерный перелет проходит в максимально безопасной, комфортной и спокойной обстановке. Флот деловой авиации комплектуется высококлассными воздушными судами, а пилоты проходят строгий отбор и периодическое повышение квалификации.',
    bblock_message4 : 'Большое разнообразие дополнительных услуг, которые можно включить в программу чартера (погрузка/разгрузка багажа вне терминала, кейтеринг на борту из лучших ресторанов по вашему выбору, наличие дополнительных бортпроводников на борту, услуги трансфера до/от аэропорта.',

    faq_header : 'Что влияет на стоимость чартера?',
    faq_message1 : 'Тип самолета, его новизна, дата обновления салона, и вместительность',
    faq_message2 : 'Количество пассажиров - чем больше пассажиров летит, тем больше стоимость их обслуживания на борту и в вип терминалах ',
    faq_message3 : 'Особые условия обслуживания чартерного рейса в аэропорту (например, загруженность аэропорта в даты проведение мероприятий (форумов), погрузка/разгрузка крупногабаритного багажа со спец техникой и др.) ',
    faq_message4 : 'Время в пути - цена напрямую зависит от расстояния до пункта назначения',
    faq_message5 : 'Возможность подбора попутных рейсов Empty leg, что дает значительную экономию средств при заказе чартерного рейса',
    faq_message6 : 'Количество дней стоянки до обратного вылета - чем дольше самолет находится на стоянке в аэропорту в режиме ожидания пассажиров, тем дороже выйдет общая стоимость полета',
    faq_caption : 'Список дополнительных услуг и отдельные изменения условий перелета могут значительно изменить его стоимость. Обычно, в общую стоимость аренды не входит оплата дополнительных летных часов, обработка самолета антиобледенительной жидкостью, не запланированная задержка вылета по вине клиентов.',

    spec_items_intl_header : 'Международные перелеты на арендованном самолете',
    spec_items_intl_destination1 : 'Москва — Минск',
    spec_items_intl_price1 : 'от 15 000 €',
    spec_items_intl_link1: 'search/UUWW/UMMS',
    spec_items_intl_destination2 : 'Москва — Баку',
    spec_items_intl_price2 : 'от 55 000 €',
    spec_items_intl_link2: 'search/UUWW/UBBB',
    spec_items_intl_destination3 : 'Москва — Нур-Султан',
    spec_items_intl_price3 : 'от 50 000 €',
    spec_items_intl_link3: 'search/UUWW/UACC',
    spec_items_intl_destination4 : 'Москва — Стамбул',
    spec_items_intl_price4 : 'от 70 000 €',
    spec_items_intl_link4: 'search/UUWW/LTFM',
    spec_items_intl_action : 'Смотреть предложения',

    spec_items_dom_header : 'Региональные перелеты на арендованном самолете',
    spec_items_dom_destination1 : 'Москва — Сочи',
    spec_items_dom_price1 : 'от 55 000 €',
    spec_items_dom_link1: 'search/UUWW/URSS',
    spec_items_dom_destination2 : 'Москва — Санкт-Петербург',
    spec_items_dom_price2 : 'от 14 000 €',
    spec_items_dom_link2: 'search/UUWW/ULLI',
    spec_items_dom_destination3 : 'Москва — Казань',
    spec_items_dom_price3 : 'от 17 000 €',
    spec_items_dom_link3: 'search/UUWW/UWKD',
    spec_items_dom_destination4 : 'Москва — Тюмень',
    spec_items_dom_price4 : 'от 40 000 €',
    spec_items_dom_link4: 'search/UUWW/USTR',
    spec_items_dom_action : 'Смотреть предложения',

    search_results_header : 'Выгодные предложения',
    search_results_caption : 'Особо выгодное предложение от авиакомпаний, которые совершают пустой перелет по вашему маршруту:',

    jet_header : 'Новые IT возможности',
    jet_caption : 'Сервис NAJET это самый быстрый способ узнать конкретное возможное расписания полета самолета и точную стоимость заказа чартера. Поисковик на сайте сервиса NAJET работает в режиме онлайн. То есть вы получите необходимую информацию о доступности самолетов для заказа чартера в течение нескольких секунд без всяких дополнительных регистраций.',

    footer_left_text: ' Компаний, которые эксплуатируют бизнес джеты не так уж и много в России – не более 10 компаний, которые совсем легально могут эксплуатировать такие самолеты и продавать их для чартерных перевозок. Таких самолетов на которых можно зказать чартер в России не более 30 самолетов. Представьте – всего тридцать самолетов, доступных для заказа чартера. Это очень мало. В США таких самолетов – тысячи, и заказ чартера там – пустяк.',
    footer_right_text: 'Частный авиаперелет или деловая поездка (бизнес перелет) – не важно – это все отнести можно к заказу чартера, и сделать это может именно тот, кто хочет полететь на бизнес джете, сам своими руками – заказать чартер онлайн. Уникальная ИТ разработка делает заказ чартера очень простым сегодня. NAJET здесь пионер, и открыл для заказа чартера новые возможности, обеспечив для желающих заказать чартер прямой доступ к авиакомпаниям, которые эксплуатируют бизнес джеты.',
}

