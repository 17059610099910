import './Footer.scss';

import { useIntl, FormattedMessage } from 'react-intl';

export default function Footer ( props ) {

    const Intl = useIntl();

    let footerClassName;
    if (props.searchPage) { footerClassName = 'search-footer'; }
    if (props.staticPage) { footerClassName = 'static-footer'; }
    if (props.flightPage) { footerClassName += ' with-margin'; }

    let leftText;
    if ( props.leftText ) { leftText = props.leftText; } else { leftText = Intl.messages['home_last_text_left']; }

    let  rightText;
    if ( props.rightText ) { rightText = props.rightText; } else { rightText = Intl.messages['home_last_text_right']; }

    let now = new Date();
    let currentYear = now.getFullYear();

    return (
        <footer className={ footerClassName }>
            <div className="line" />
            <div className="copyrights"><FormattedMessage id='copyrights' />{ currentYear }</div>
            <div className="last_text">
                <span className="left" dangerouslySetInnerHTML={ {__html: leftText} } />&nbsp;
                <span className="right" dangerouslySetInnerHTML={ {__html: rightText} } />
            </div>
        </footer>
    );
}
