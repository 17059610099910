import './ErrorContent.scss';

import { useIntl, FormattedMessage } from 'react-intl';
import { Link } from "react-router-dom";

export default function ErrorContent () {
    Intl = useIntl();
    return (
        <div className="error-content">
            <div className="error-content-header"><FormattedMessage id='error_code' /></div>
            <div className="error-content-caption">
                <span><FormattedMessage id='error_message' /></span>
                <Link to='/' className="error-content-caption-link">
                    <FormattedMessage id='back_to_main_page' />
                </Link>
            </div>
        </div>
    );
}
