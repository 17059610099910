export const VIP_CHARTER = {
    title : 'Вип чартерные рейсы. Мгновенное предложение онлайн. NAJET.',
    description : 'Мы представляем онлайн платформу по организации вип чартерных рейсов. NAJET помогает современным и успешным деловым людям летать с комфортом.',
    keywords: 'чартерный рейс, вип рейс, вип чартер, випчартер',

    header : 'Онлайн-сервис аренды вип чартерного рейса',

    bblock_header : 'Преимущества аренды вип чартера',
    bblock_caption : 'Заказывая вип чартер, человек приобретает следующие условия в полёте:',
    bblock_message1 : 'Вы летите только своей компанией или семьей, никого постороннего, что особенно важно в реалиях современного мира с учетом эпидемии COVID-19 и других факторов, угрожающих безопасности Вашему здоровью и здоровью Ваших близких.',
    bblock_message2 : 'Заказ VIP чартерного рейса может иметь решающее значение для вашего бизнеса, особенно если вы часто проводите встречи в разных местах. Чартер самолетов экономит ваше время и дает вам большую гибкость: вам не нужно прибывать в аэропорт за несколько часов до вылета, вы можете изменить свою поездку, чтобы учесть погодные задержки, если это необходимо.',
    bblock_message3 : ' Вы можете легко летать между своими встречами в напряженные дни, не боясь пропустить свои рейсы. Кроме того, Заказ VIP чартерного рейса позволяет вам максимально использовать свое время, потому что со всеми удобствами, предлагаемыми бизнес-самолетами, вы можете оставаться продуктивными на борту.',
    bblock_message4 : ' Заказать VIP чартерный рейс – это значит получить персональное обслуживание в аэропорту, личного менеджера-организатора перевозки. Заказчик сам выбирает самолет который подходит ему по всем параметрам, включая вместимость, дальность полета, оборудование и комфортабельность салона, класс обслуживания и многое другое.',

    faq_header : 'Что влияет на стоимость VIP чартера?',
    faq_message1 : 'Тип самолета, его новизна, дата обновления салона, и вместительность',
    faq_message2 : 'Количество пассажиров - чем больше пассажиров летит, тем больше стоимость их обслуживания на борту и в вип терминалах ',
    faq_message3 : 'Особые условия обслуживания VIP чартерного рейса в аэропорту (например, загруженность аэропорта в даты проведение мероприятий (форумов), погрузка/разгрузка крупногабаритного багажа со спец техникой и др.) ',
    faq_message4 : 'Время в пути - цена напрямую зависит от расстояния до пункта назначения',
    faq_message5 : 'Возможность подбора попутных рейсов Empty leg, что дает значительную экономию средств при заказе VIP чартерного рейса',
    faq_message6 : 'Количество дней стоянки до обратного вылета - чем дольше самолет находится на стоянке в аэропорту в режиме ожидания пассажиров, тем дороже выйдет общая стоимость полета',
    faq_caption : 'Стоимость заказа VIP чартера отличается в зависимости от пассажировместимости, дальности полета, особенностей конструкции, комфортабельности салона и даты его обновления. Как правило, чем больше джет, тем выше стоимость летного часа',

    spec_items_intl_header : 'Международные перелеты на арендованном бизнес джете',
    spec_items_intl_destination1 : 'Москва — Минск',
    spec_items_intl_price1 : 'от 15 000 €',
    spec_items_intl_link1: 'search/UUWW/UMMS',
    spec_items_intl_destination2 : 'Москва — Баку',
    spec_items_intl_price2 : 'от 55 000 €',
    spec_items_intl_link2: 'search/UUWW/UBBB',
    spec_items_intl_destination3 : 'Москва — Нур-Султан',
    spec_items_intl_price3 : 'от 50 000 €',
    spec_items_intl_link3: 'search/UUWW/UACC',
    spec_items_intl_destination4 : 'Москва — Стамбул',
    spec_items_intl_price4 : 'от 70 000 €',
    spec_items_intl_link4: 'search/UUWW/LTFM',
    spec_items_intl_action : 'Смотреть предложения',
    spec_items_dom_header : 'Региональные перелеты на арендованном бизнес джете',
    spec_items_dom_destination1 : 'Москва — Сочи',
    spec_items_dom_price1 : 'от 55 000 €',
    spec_items_dom_link1: 'search/UUWW/URSS',
    spec_items_dom_destination2 : 'Москва — Санкт-Петербург',
    spec_items_dom_price2 : 'от 14 000 €',
    spec_items_dom_link2: 'search/UUWW/ULLI',
    spec_items_dom_destination3 : 'Москва — Казань',
    spec_items_dom_price3 : 'от 17 000 €',
    spec_items_dom_link3: 'search/UUWW/UWKD',
    spec_items_dom_destination4 : 'Москва — Тюмень',
    spec_items_dom_price4 : 'от 40 000 €',
    spec_items_dom_link4: 'search/UUWW/USTR',
    spec_items_dom_action : 'Смотреть предложения',

    search_results_header : 'Выгодные предложения',
    search_results_caption : 'Особо выгодное предложение от авиакомпаний, которые совершают пустой перелет по вашему маршруту:',

    jet_header : ' Онлайн сервис по выбору и заказу VIP чартерного рейса на самолетах бизнес-авиации ',
    jet_caption : ' Сервис NAJET всего за несколько секунд поиска сформирует все доступные на рынке предложения на авиаперевозку. Будут учтены все пожелания клиента. Заказчик VIP чартера получит исчерпывающие данные о: видах самолетов, количестве мест в них, стоимости услуги. Клиент сможет по фотографиям воздушного судна определить какой класс самолета ему подходит, ознакомиться с особенностями салона. Работа компании NAJET построена по принципу Агрегатора такси, но только на самолетах VIP, бизнес-авиации.',

    footer_left_text: 'Сервис NAJET это самый быстрый способ узнать конкретное возможное расписания полета бизнес джета и точную стоимость аренды. Поисковик на сайте сервиса NAJET работает в режиме онлайн. То есть вы получите необходимую информацию о возможных для заказа VIP чартера в течение секунд без всяких дополнительных регистраций. Cервис заказа VIP чартерного рейса NAJET позволяет получать мгновенное предложение с ценой и фото самолета онлайн.',
    footer_right_text: ' В связи политической ситуацией в мире поиск перелетов по большинству международным направлениям не может быть осуществлена в автоматическом онлайн режиме из-за значительных ограничений в международных полетах из России и в Россию. В этой связи в случае, если Вы не нашли требуемый аэропорт в поисковике на сайте сервиса NAJET, Вам следует обратиться удобным способом к специалисту клиентского сервиса по контактам, указанным на сайте NAJET.',
}

