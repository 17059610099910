export const AVIABROKER = {
    title : 'Авиаброкер NAJET. Мгновенное предложение онлайн.',
    description : 'NAJET – ваш персональный авиаброкер, который сделает любое путешествуйте максимально комфортным, используя все преимущества бизнес-авиации. Стоимость, цена, удобный сервис бронирования частных самолетов.',
    keywords: 'авиаброкер',

    header : 'Онлайн-сервис как профессиональный авиаброкер',

    bblock_header : 'Кто такой авиаброкер?',
    bblock_caption : 'Брокерские услуги есть в любой экономической сфере. Брокер – это посредник, действует от имени или по поручению одного лица.',
    bblock_message1 : 'На авиационном рынке также работают брокеры – авиаброкеры. Авиаброкер достаточно важен при организации любого  чартерного перелета, и особенно частного перелета на бизнес джете.',
    bblock_message2 : 'Основные функции авиаброкера – продажа, организация, контроль. Классический штатный состав авиаброкера такой: продавец-operations-супервайзер. У каждого работника своя функция и задача.',
    bblock_message3 : 'Работой с Клиентами у авиаброкера занимается продавец – сотрудник, который знает, зачастую, лично человека, заказывающего частный авиаперелет, знает все особенности Клиента, все его прихоти и желания. Продавец также работает с авиакомпаниями, эксплуатантами бизнес джетов.',
    bblock_message4 : 'Оperations – специальный человек, который контролирует организацию всего и вся – это такой back office авиаброкера. Он не видим для Клиента, но от его понимания процессов зависит удовлетворенность Клиента, слаженность работы всех вовлеченных в организацию чартерного авиарейса сторон.',

    faq_header : 'В чем заключается работа авиаброкера?',
    faq_message1 : 'Взаимодействие с Клиентами (заказчиками частных авиарейсов) и с авиакомпаниями, эксплуатирующими бизнес джет',
    faq_message2 : 'Контроль за организацией и слаженностью работы всех вовлеченных в осуществление чартерного авиарейса сторон',
    faq_message3 : 'Встреча и обслуживание пассажиров в VIP-терминалах аэропортов вылета и прибытия',
    faq_message4 : 'Взаимодействие с кейтеринговыми агентствами - компаниями, предоставляющими бортовое питание',
    faq_message5 : 'Взаимодействие с транспортными и  другими компаниями, которые организуют сервисы для частного авиаперелета',
    faq_message6 : 'Поддержка своих Клиентов в формате 24/7',
    faq_caption : 'Каждый рейс требует внимания, и поэтому зачастую у авиаброкера есть так называемый operations – специальный отдел, который контролирует все детали по организации частного авиарейса. Классический штатный состав авиаброкера такой: продавец-operations-супервайзер. У каждого работника своя функция и задача. Но иногда бывает, что авиаброкер – это 3 в 1, то есть один человек, который делает все сам: продает, организовывает и контролирует.',

    spec_items_intl_header : 'Международные перелеты на арендованном самолете',
    spec_items_intl_destination1 : 'Москва — Минск',
    spec_items_intl_price1 : 'от 15 000 €',
    spec_items_intl_link1: 'search/UUWW/UMMS',
    spec_items_intl_destination2 : 'Москва — Баку',
    spec_items_intl_price2 : 'от 55 000 €',
    spec_items_intl_link2: 'search/UUWW/UBBB',
    spec_items_intl_destination3 : 'Москва — Нур-Султан',
    spec_items_intl_price3 : 'от 50 000 €',
    spec_items_intl_link3: 'search/UUWW/UACC',
    spec_items_intl_destination4 : 'Москва — Стамбул',
    spec_items_intl_price4 : 'от 70 000 €',
    spec_items_intl_link4: 'search/UUWW/LTFM',
    spec_items_intl_action : 'Смотреть предложения',

    spec_items_dom_header : 'Региональные перелеты на арендованном самолете',
    spec_items_dom_destination1 : 'Москва — Сочи',
    spec_items_dom_price1 : 'от 55 000 €',
    spec_items_dom_link1: 'search/UUWW/URSS',
    spec_items_dom_destination2 : 'Москва — Санкт-Петербург',
    spec_items_dom_price2 : 'от 14 000 €',
    spec_items_dom_link2: 'search/UUWW/ULLI',
    spec_items_dom_destination3 : 'Москва — Казань',
    spec_items_dom_price3 : 'от 17 000 €',
    spec_items_dom_link3: 'search/UUWW/UWKD',
    spec_items_dom_destination4 : 'Москва — Тюмень',
    spec_items_dom_price4 : 'от 40 000 €',
    spec_items_dom_link4: 'search/UUWW/USTR',
    spec_items_dom_action : 'Смотреть предложения',

    search_results_header : 'Выгодные предложения',
    search_results_caption : 'Особо выгодное предложение от авиакомпаний, которые совершают пустой перелет по вашему маршруту:',

    jet_header : ' Хороший авиаброкер ',
    jet_caption : ' Хороший, полноценный авиаброкер знает авиационный рынок, что называется, вдоль и поперек. Каждая авиакомпания, эксплуатирующая бизнес джет в картотеке у авиаброкера, каждый самолет занесен в базу с указанием всех характеристик частного самолета, особенностей по организации авиаперелета. Заказ чартера может быть любым, и авиаброкер должен справиться с этой задачей – организовать частный рейс любой сложности, предусмотрев все детали, отработав все нюансы с клиентом, с авиакомпанией, предоставляющей бизнес джет, с аэропортами вылета и прилета. В компания NAJET работают специалисты высокого уровня, которые всегда помогут вам в организации рейса и остаются на связи 24/7 ',

    footer_left_text: 'На сегодняшний день в России более 100 авиаброкеров. Есть крупные игроки, чье имя известно и на слуху, которые продают по 50+ чартерных авиарейсов в месяц, есть более мелкие, которые 5-10 рейсов в месяц продали и спокойно работают дальше. Всем сегодня место хватает, у каждого свой Клиент, свой заказчик бизнес перелетов.',
    footer_right_text: 'NAJET стремится облегчить работу авиаброкера путем автоматизации некоторых процессов, экономя время на обработку данных от авиакомпаний и формирование предложения клиенту. Тем самым стабилизируются цены за счет конкуренции, процесс получения предложений становится прозрачным и мгновенным, наши авиаброкеры в более выгодном положении по отношению к своим клиентам за счет быстрой возможности формирования нескольких предложений на выбор.',
}
