export const ZAKAZ_SAMOLETA= {
    title : 'Заказ частного самолета. Мгновенное предложение онлайн. NAJET.',
    description : 'Мы предоставляем удобный онлайн сервис по заказу самолетов, который позволит вам отправиться на следующую деловую встречу, на частный остров или на выходные в полном комфорте и уединении.',
    keywords: 'заказ частного самолета, заказ самолета',

    header : 'Онлайн-сервис заказа самолета',

    bblock_header : 'Что влияет на стоимость заказа частного самолета',
    bblock_caption : 'Стоимость заказа частного самолета складывается из множества факторов. Вот основные из них:',
    bblock_message1 : ' Маршрут. Авиакомпании рассчитывают стоимость заказа частного самолета от дальности перелета. Как правило, существует определенная цена за час лета. Соответственно, чем дальше перелет, тем выше цена такого рейса. Рассчитывается необходимое количество топлива, рабочие смены экипажа и необходимость отдыха между перелетами и т.д. ',
    bblock_message2 : ' Тип самолета. Чем больше частный самолет, тем дороже цена заказа такого самолета.  Чем легче и меньше частный самолет (например на 5 мест), тем будет дешевле его аренда по сравнению с большим самолетами (на 13-16 мест). Также влияние оказывает год выпуска самолета и состояние его салона.',
    bblock_message3 : ' Кол-во пассажиров. Для каждого пассажира нужен VIP терминал или бизнес-зал, а также VIP бортовое питание. Примерный расход на 1 пассажира при полете в одну сторону внутри России или на международном рейсе составляют 800-1200€.',
    bblock_message4 : ' Стоянка ВС. Разворотный рейс туда-обратно будет стоить дешевле рейса со стоянкой (например, 2-3 дня), т.к. при этом придется оплатить проживание экипажа в гостинице, питание экипажа, парковку самолета в аэропорту, простой самолета без полета и др.',

    faq_header : 'Преимущества заказа частного самолета',
    faq_message1 : ' Свобода путешествий. Вы сами определяете время и маршрут при оформлении заказа на частный самолет.',
    faq_message2 : ' Оперативность и точность. Вылет состоится точно в оговоренное время, независимо от дня недели и времени суток.',
    faq_message3 : ' Подбор самолета под ваши запросы. Заказчик получает предложения на все частные самолеты, которые могут выполнить данный заказ.',
    faq_message4 : ' Высокий уровень безопасности. Флот деловой авиации комплектуется высококлассными воздушными судами, а пилоты проходят строгий отбор и периодическое повышение квалификации.',
    faq_message5 : ' Приватность и комфорт. На борту нет посторонних пассажиров, оборудование салона обеспечивает максимально комфортные условия, во время перелета.',
    faq_message6 : 'Скорость принятия решения. Заказать частный самолет можно с отправлением уже через несколько часов с момента оформления заказа.',
    faq_caption : '   Безусловно, главные преимущества заказа частного самолета заключаются в его безопасности, скорости и комфорте. Все минусы, присутствующие на стандартных рейсах, на частном самолёте отсутствуют. Всегда приятно лететь в удобстве, не думая о безопасности и надёжности, ведь её обеспечивают в полной мере профессионалы.',

    spec_items_intl_header : 'Международные перелеты на арендованном бизнес джете',
    spec_items_intl_destination1 : 'Москва — Минск',
    spec_items_intl_price1 : 'от 15 000 €',
    spec_items_intl_link1: 'search/UUWW/UMMS',
    spec_items_intl_destination2 : 'Москва — Баку',
    spec_items_intl_price2 : 'от 55 000 €',
    spec_items_intl_link2: 'search/UUWW/UBBB',
    spec_items_intl_destination3 : 'Москва — Нур-Султан',
    spec_items_intl_price3 : 'от 50 000 €',
    spec_items_intl_link3: 'search/UUWW/UACC',
    spec_items_intl_destination4 : 'Москва — Стамбул',
    spec_items_intl_price4 : 'от 70 000 €',
    spec_items_intl_link4: 'search/UUWW/LTFM',
    spec_items_intl_action : 'Смотреть предложения',
    spec_items_dom_header : 'Региональные перелеты на арендованном бизнес джете',
    spec_items_dom_destination1 : 'Москва — Сочи',
    spec_items_dom_price1 : 'от 55 000 €',
    spec_items_dom_link1: 'search/UUWW/URSS',
    spec_items_dom_destination2 : 'Москва — Санкт-Петербург',
    spec_items_dom_price2 : 'от 14 000 €',
    spec_items_dom_link2: 'search/UUWW/ULLI',
    spec_items_dom_destination3 : 'Москва — Казань',
    spec_items_dom_price3 : 'от 17 000 €',
    spec_items_dom_link3: 'search/UUWW/UWKD',
    spec_items_dom_destination4 : 'Москва — Тюмень',
    spec_items_dom_price4 : 'от 40 000 €',
    spec_items_dom_link4: 'search/UUWW/USTR',
    spec_items_dom_action : 'Смотреть предложения',

    search_results_header : 'Выгодные предложения',
    search_results_caption : 'Особо выгодное предложение от авиакомпаний, которые совершают пустой перелет по вашему маршруту:',

    jet_header : ' Преимущества заказа частного самолета с использованием Платформы NAJET',
    jet_caption : ' В результате работы Платформы NAJET, в реальном времени формируются предложения на частный авиаперелет на самолетах, которые подходят по техническим характеристикам и другим параметрам, свободные в нужное клиенту время. Заказчику поступят предложения, в которых детально будет указана информация: вид самолета и его номер, количество пассажирских мест, cтоимость частного авиаперелета. Отдельным преимуществом Платформы NAJET является предложение так называемых «Empty Leg» - попутных рейсов. При заказе такого частного авиаперелета Вы можете сэкономить значительную сумму денег.',

    footer_left_text: 'Сервис NAJET это самый быстрый способ узнать конкретное возможное расписания полета частного самолета и точную стоимость его заказа. Поисковик на сайте сервиса NAJET работает в режиме онлайн. То есть вы получите необходимую информацию о возможных для заказа частных самолетов в течение секунд без всяких дополнительных регистраций. Cервис заказа частного самолета NAJET позволяет получать мгновенное предложение с ценой и фото самолета онлайн.',
    footer_right_text: ' Заказывайте частный самолет с NAJET, если вы хотите отправиться на следующую деловую встречу, на частный остров или на выходные в полном комфорте и уединении — без головной боли или хлопот. Ведь привычные многим регулярные авиарейсы иногда просто не в состоянии полноценно удовлетворить потребности клиента. С помощью NAJET вы сможете воспользоваться услугой заказа частного самолета без всякого труда. Процесс занимает не больше времени, чем привычный заказ такси.',
}

