import './SearchForm.scss';
import { useIntl, FormattedMessage, FormattedDate, FormattedTime } from 'react-intl';
import React, { useState } from 'react';

import PaxButton from "./PaxButton";
import DataPicker from "../components/DataPicker";
import GetAverageFlightTime from "../components/GetAverageFlightTime";

import departure_icon from "../assets/departure.svg";
import arrival_icon from "../assets/arrival.svg";
import calendar_icon from "../assets/calendar.svg";

export default function SearchForm(props)  {
    const Intl = useIntl();
    const [whichDateChoosing, setWhichDateChoosing] = useState(null);
    const [departureBackDate, setDepartureBackDate] = useState(null);
    const [dataPickerOffset, setDataPickerOffset] = useState(false);

    const averageFlightTime = GetAverageFlightTime({ flightsData : null });

    const isSubmitActive = () => {
        return props.fromSelected !== null && props.toSelected !== null && props.departureDate !== null && props.pax !== null;
    };

    function AirportSelected(props) {

        let name;
        let city;

        if (Intl.locale === 'ru-RU') {
            name = props.airport.name_rus;
            city = props.airport.city_rus;
        } else {
            name = props.airport.name;
            city = props.airport.city;
        }

        return (
            <div className="airport_selected">
                <div className="container">
                    <div className="name">{ name }</div>
                    <div className="location">{ city }</div>
                </div>
                <div className="iata">{ props.airport.iata }</div>
                <div className="icao">{ props.airport.icao }</div>
            </div>
        );
    }

    function DateSelected(props) {
        return (
            <div className="selected-date">
                <div className="selected-date-date"><FormattedDate value={ props.date } day='2-digit' month='long' />,</div>
                <div className="selected-date-time">в&nbsp;<FormattedTime value={ props.date } /></div>
            </div>
        );
    }

    function FormWhere() {
        return (
            <div className="action">
                <FormattedMessage id='departure_airport' />&nbsp;&nbsp;
                <img src={ departure_icon } alt={ Intl.messages['departure_airport'] } />
            </div>
        );
    }

    function FormTo() {
        return (
            <div className="action">
                <FormattedMessage id='arrival_airport' />&nbsp;&nbsp;
                <img src={ arrival_icon } alt={ Intl.messages['arrival_airport'] } />
            </div>
        );
    }

    function FormDate() {
        return (
            <div className="action">
                <FormattedMessage id='selectDate' />&nbsp;&nbsp;
                <img src={ calendar_icon } alt={ Intl.messages['selectDate'] } />
            </div>
        );
    }

    function paxMoreChangeHandler() {
        props.setPax(props.pax + 1);
    }

    function paxLessChangeHandler() {
        if (props.pax > 1) {
            props.setPax(props.pax - 1);
        }
    }

    return (
        <div className="search" style={{ display: !props.isSearching ? 'flex' : 'none' }}>
            <h2 className="search-heading-mob">
                <FormattedMessage id='search_flights' />
            </h2>
            <div className="form">
                <div className="form-items">
                    <div className="where" onClick={ () =>  props.handleIsSearchingChange() }>
                        <div className={ props.fromSelected ? 'caption_selected' : 'caption' }><FormattedMessage id='from' /></div>
                        { props.fromSelected ? <AirportSelected airport = { props.fromSelected } /> : <FormWhere /> }
                    </div>
                    <div className="to" onClick={ () =>  props.handleIsToSearchingChange() }>
                        <div className={ props.toSelected ? 'caption_selected' : 'caption' }><FormattedMessage id='to' /></div>
                        { props.toSelected ? <AirportSelected airport = { props.toSelected } /> : <FormTo /> }
                    </div>
                    <div className="date" onClick={ () => setWhichDateChoosing('there') }>
                        <div className={ props.departureDate ? 'caption_selected' : 'caption' }><FormattedMessage id='when' /></div>
                        <div className="date-content">{ props.departureDate ? <DateSelected date = { props.departureDate } /> : <FormDate /> }</div>
                    </div>
                    { whichDateChoosing &&
                        <div className="modal-window" onClick={ () => setWhichDateChoosing(null) }/>
                    }
                    <DataPicker
                        averageFlightTime={ averageFlightTime }
                        departureDate={ props.departureDate }
                        setDepartureDate={ props.setDepartureDate }
                        departureBackDate={ departureBackDate }
                        setDepartureBackDate={ setDepartureBackDate }
                        whichDateChoosing={ whichDateChoosing }
                        setWhichDateChoosing={ setWhichDateChoosing }
                        dataPickerOffset={ dataPickerOffset }
                        setDataPickerOffset={ setDataPickerOffset }
                    />
                </div>
                <div className="form-footer">
                    <PaxButton
                        pax = { props.pax }
                        setMorePax = { paxMoreChangeHandler }
                        setLessPax = { paxLessChangeHandler }
                    />
                    <button className="form-submit" onClick={ () => props.submit() } disabled={!isSubmitActive()}>
                        <span>
                            <FormattedMessage id='search' />
                        </span>
                    </button>
                </div>
            </div>

        </div>
    );
}
