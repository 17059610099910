import './SeoFaq.scss';

export default function SeoFaq(props) {
    return (
        <div className="seo-faq">
            <div className="seo-faq-header">{ props.header }</div>
            <div className="seo-faq-content">
                <ul className="seo-faq-left-ul">
                    <li>{ props.message1 }</li>
                    <li>{ props.message2 }</li>
                    <li>{ props.message3 }</li>
                </ul>
                <ul>
                    <li>{ props.message4 }</li>
                    <li>{ props.message5 }</li>
                    <li>{ props.message6 }</li>
                </ul>
            </div>
            <div className="seo-faq-caption">{ props.caption }</div>
        </div>
    );
}