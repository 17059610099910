import './SeoJet.scss';

import jetPic from '../assets/seo-private-jet.png';

export default function SeoJet(props)  {
    return (
        <div className="seo-jet">
            <div className="seo-jet-top">
                <div className="seo-jet-header">{ props.header }</div>
                <div className="seo-jet-caption">{ props.caption }</div>
            </div>
            <img src={ jetPic } alt={ props.header } />
        </div>
    );
}