export const ARENDA_SAMOLETA = {
    title : 'Аренда частного самолета. Мгновенное предложение онлайн. NAJET.',
    description : 'Арендуйте частный самолёт и путешествуйте с максимальным комфортом, используя все преимущества бизнес-авиации. Стоимость, цена, удобнай сервис бронирования частных самолетов.',
    keywords: 'аренда самолета, аренда частного самолета',

    header : 'Онлайн-сервис аренды частного самолета',

    bblock_header : 'Преимущества аренды частного самолета',
    bblock_caption : 'Аренда частного самолета для выполнения чартерного рейса дает клиентам следующие преимущества:',
    bblock_message1 : 'Свобода путешествий. Вы сами определяете время и маршрут полета частного самолета',
    bblock_message2 : 'Оперативность и точность. Вылет состоится точно в оговоренное время, независимо от дня недели и времени суток. Арендовать самолет можно с отправлением уже через несколько часов с момента оформления заказа',
    bblock_message3 : 'Подбор самолета под ваши запросы. Заказчик получает предложения на все самолеты, которые могут выполнить заявленный перелет. Заказчик сам выбирает самолет который подходит ему по всем параметрам, включая вместимость, дальность полета, оборудование и комфортабельность салона, класс обслуживания и многое другое',
    bblock_message4 : 'Высокий уровень безопасности. Флот деловой авиации комплектуется высококлассными воздушными судами, а пилоты проходят строгий отбор и периодическое повышение квалификации. Использование самолета это залог абсолютной безопасности',

    faq_header : 'Чем отличается полет на арендованном самолете?',
    faq_message1 : 'Кейтеринг во время перелета, в том числе обслуживание эксклюзивного уровня',
    faq_message2 : 'Предоставление высокоскоростного интернета на борту самолета (в зависимости от технической возможности)',
    faq_message3 : 'Обслуживание пассажиров в VIP-терминалах аэропортов вылета и прибытия',
    faq_message4 : 'Возможность внесения некоторых изменений в расписание и колличестве пассажиров',
    faq_message5 : 'Возможность подбора попутных рейсов Empty leg, что дает значительную экономию средств на аренде частного самолета',
    faq_message6 : '24 часа в сутки клиентский сервис от Сервиса NAJET',
    faq_caption : 'Отдельные изменения условий перелета могут значительно изменить его стоимость. Обычно, в общую стоимость аренды не входит оплата дополнительных летных часов, обработка самолета антиобледенительной жидкостью, не запланирования ночевка в ожидании пассажиров, или задержка вылета по вине клиентов.',

    spec_items_intl_header : 'Международные перелеты на арендованном самолете',
    spec_items_intl_destination1 : 'Москва — Минск',
    spec_items_intl_price1 : 'от 15 000 €',
    spec_items_intl_link1: 'search/UUWW/UMMS',
    spec_items_intl_destination2 : 'Москва — Баку',
    spec_items_intl_price2 : 'от 55 000 €',
    spec_items_intl_link2: 'search/UUWW/UBBB',
    spec_items_intl_destination3 : 'Москва — Нур-Султан',
    spec_items_intl_price3 : 'от 50 000 €',
    spec_items_intl_link3: 'search/UUWW/UACC',
    spec_items_intl_destination4 : 'Москва — Стамбул',
    spec_items_intl_price4 : 'от 70 000 €',
    spec_items_intl_link4: 'search/UUWW/LTFM',
    spec_items_intl_action : 'Смотреть предложения',

    spec_items_dom_header : 'Региональные перелеты на арендованном самолете',
    spec_items_dom_destination1 : 'Москва — Сочи',
    spec_items_dom_price1 : 'от 55 000 €',
    spec_items_dom_link1: 'search/UUWW/URSS',
    spec_items_dom_destination2 : 'Москва — Санкт-Петербург',
    spec_items_dom_price2 : 'от 14 000 €',
    spec_items_dom_link2: 'search/UUWW/ULLI',
    spec_items_dom_destination3 : 'Москва — Казань',
    spec_items_dom_price3 : 'от 17 000 €',
    spec_items_dom_link3: 'search/UUWW/UWKD',
    spec_items_dom_destination4 : 'Москва — Тюмень',
    spec_items_dom_price4 : 'от 40 000 €',
    spec_items_dom_link4: 'search/UUWW/USTR',
    spec_items_dom_action : 'Смотреть предложения',

    search_results_header : 'Выгодные предложения',
    search_results_caption : 'Особо выгодное предложение от авиакомпаний, которые совершают пустой перелет по вашему маршруту:',

    jet_header : 'Стоимость аренды самолета',
    jet_caption : 'Сервис NAJET это самый быстрый способ узнать конкретное возможное расписания полета самолета и точную стоимость аренды. Поисковик на сайте сервиса NAJET работает в режиме онлайн. То есть вы получите необходимую информацию о возможных для аренды самолета в течение секунд без всяких дополнительных регистраций.',

    footer_left_text: 'Cервис аренды частного самолета NAJET позволяет получать мгновенное предложение с ценой и фото самолета онлайн. Арендуйте самолет с NAJET, если вы хотите отправиться на следующую деловую встречу, на частный остров или на выходные в полном комфорте и уединении — без головной боли или хлопот. Ведь привычные многим регулярные авиарейсы иногда просто не в состоянии полноценно удовлетворить потребности клиента. С помощью NAJET вы сможете воспользоваться услугой аренды частного самолета без всякого труда.  Процесс занимает не больше времени, чем привычный заказ такси.',
    footer_right_text: 'Процедура аренды частного самолета и формирования стоимости авиаперевозок всегда была сложным и малоприятным занятием. Для итогового согласования перелёта заказчик рейса был обязан на постоянной основе созваниваться с авиакомпанией или авиаброкером. Раньше заказчик просто не мог получить чёткий и своевременный ответ о необходимом рейсе. Это было связано в первую очередь с тем, что менеджеры авиакомпаний должны были заниматься мониторингом огромного массива данных, которые напрямую связаны с качеством оказываемых услуг и возможностью авиаперелёта как такового.',
}