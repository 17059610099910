import './SearchFilters.scss';
import './RangeSlider.css';

import returnPicActive from '../assets/return-pic-active.svg';
import returnPic from '../assets/return-pic.svg';
import advancedPic from '../assets/advanced.png';

import { useIntl, FormattedMessage, FormattedNumber } from "react-intl";
import React, { useEffect, useState } from "react";
import Slider from 'react-rangeslider';

import ConvertToInternationalCurrencySystem from "./СonvertToInternationalCurrencySystem";
import PaxButton from "./PaxButton";
import FilterButton from "./FilterButton";

export default function SearchFilters (props)  {

    const [defaultFlightsData, setDefaultFlightsData] = useState( null );
    const [isFiltersPopupOpened, setIsFiltersPopupOpened] = useState(false);

    const [sortFlightsDataProp, setSortFlightsDataProp] = useState('LowPrice');
    const [filterPrice, setFilterPrice] = useState(0);
    const [filterType, setFilterType] = useState(null);

    let sortItemLowPriceClass = 'filters-popup-sort-item filters-popup-sort-item-lowprice';
    let sortItemHightPriceClass = 'filters-popup-sort-item filters-popup-sort-item-hightprice';
    let sortItemMaxPaxClass = 'filters-popup-sort-item filters-popup-sort-item-maxpax';
    let sortItemYearClass = 'filters-popup-sort-item filters-popup-sort-item-year';

    let filterTypeTurboPropClass = 'filters-popup-type-item filters-popup-type-turboprop';
    let filterMidSizeJetClass = 'filters-popup-type-item filters-popup-type-midsizejet';
    let filterSuperJetClass = 'filters-popup-type-item filters-popup-type-superjet';

    let defaultCurrencyRubClass = 'price-filter-currency-item price-filter-currency-item-rub';
    let defaultCurrencyUsdClass = 'price-filter-currency-item price-filter-currency-item-usd';
    let defaultCurrencyEurClass = 'price-filter-currency-item price-filter-currency-item-eur';

    const Intl = useIntl();

    let priceLimits = {
        highestPrice : 0,
        lowestPrice: 0,
        symbol: null,
    };

    useEffect(() => {
        if ( defaultFlightsData && defaultFlightsData.cards.length >0 ) {
            let sortedFleetData = defaultFlightsData.cards.slice();
            if (sortedFleetData[0].routes[1])
            {
                if ( sortFlightsDataProp === 'LowPrice') { sortedFleetData.sort((prev, next) => prev.routes[0].price[props.currency] + prev.routes[1].price[props.currency] - next.routes[0].price[props.currency] - next.routes[1].price[props.currency] ); }
                if ( sortFlightsDataProp === 'HightPrice') { sortedFleetData.sort((prev, next) =>  next.routes[0].price[props.currency] + next.routes[1].price[props.currency] - prev.routes[0].price[props.currency] - prev.routes[1].price[props.currency] ); }
                sortedFleetData = sortedFleetData.filter( item => ( item.routes[0].price[props.currency] + item.routes[1].price[props.currency] ) <= filterPrice );
            } else {
                if ( sortFlightsDataProp === 'LowPrice') { sortedFleetData.sort((prev, next) => prev.routes[0].price[props.currency] - next.routes[0].price[props.currency] ); }
                if ( sortFlightsDataProp === 'HightPrice') { sortedFleetData.sort((prev, next) =>  next.routes[0].price[props.currency] - prev.routes[0].price[props.currency] ); }
                sortedFleetData = sortedFleetData.filter( item => item.routes[0].price[props.currency] <= filterPrice );
            }

            if ( sortFlightsDataProp === 'MaxPax') { sortedFleetData.sort((prev, next) =>  next.routes[0].max_pax - prev.routes[0].max_pax ); }
            if ( sortFlightsDataProp === 'Year') { sortedFleetData.sort((prev, next) =>  next.routes[0].aircraft_year_of_creation -prev.routes[0].aircraft_year_of_creation ); }

            if ( filterType === 'TurboProp' ) { sortedFleetData = sortedFleetData.filter( item =>
                item.routes[0].aircraft_class === 'Turboprop'
            ); }
            if ( filterType === 'MidSizeJet' ) { sortedFleetData = sortedFleetData.filter( item =>
                item.routes[0].aircraft_class === 'Light jet' ||
                item.routes[0].aircraft_class === 'Mid-size jet'
            ); }
            if ( filterType === 'SuperJet' ) { sortedFleetData = sortedFleetData.filter( item =>
                item.routes[0].aircraft_class === 'Bizliners' ||
                item.routes[0].aircraft_class === 'Large jet' ||
                item.routes[0].aircraft_class === 'Long range jet' ||
                item.routes[0].aircraft_class === 'Super mid-size jet'
            ); }
            changeFlightsData(sortedFleetData);
        }
    }, [sortFlightsDataProp, filterPrice, filterType, defaultFlightsData])

    function changeFlightsData( cards ) {
        props.setFlightsData({
            id : defaultFlightsData.id,
            datetime : defaultFlightsData.datetime,
            query : Object.assign({}, defaultFlightsData.query),
            cards : cards.slice(),
        });
    }

    function changeDefaultCurrency( currency ){
        if ( props.currency !== currency ) {
            props.setCurrency( currency );
            setFilterPrice( null );
        }
    }

    function changeSortFlightsDataProp( prop ){
        if ( sortFlightsDataProp !== prop ) { setSortFlightsDataProp(prop); } else { setSortFlightsDataProp(null); }
    }

    function changeIsFiltersPopupOpened( boolean ){
        if (defaultFlightsData && defaultFlightsData.cards.length > 0) {
            if (boolean && window.screen.height > 720) {
                document.body.style.overflow = "hidden";
            } else {
                document.body.style.overflow = "scroll";
            }
            setIsFiltersPopupOpened( boolean );
        }
    }

    function changeFilterType( type ){
        if ( filterType !== type ) { setFilterType(type); } else { setFilterType(null); }
    }

    // Сохраняем дубликат полных результатов запроса, или меняем если запрос изменился
    if ( props.flightsData ) {
        if ( defaultFlightsData?.id !== props.flightsData.id ) {
            setDefaultFlightsData( {
                id : props.flightsData.id,
                datetime : props.flightsData.datetime,
                query : Object.assign({}, props.flightsData.query ),
                cards : props.flightsData.cards.slice(),
            });
            setFilterPrice(0);
        }
    }

    // Находим priceLimits
    defaultFlightsData?.cards.forEach((item) => {
        let totalPrice = item.routes[0].price[props.currency];
        if (item.routes[1]) { totalPrice = totalPrice + item.routes[1].price[props.currency] }
        if ( totalPrice > priceLimits.highestPrice ) { priceLimits.highestPrice = totalPrice; }
        if ( totalPrice < priceLimits.lowestPrice || !priceLimits.lowestPrice) { priceLimits.lowestPrice = totalPrice; }
    });

    if (defaultFlightsData?.cards.length > 0 && !filterPrice) { setFilterPrice(priceLimits.highestPrice); }

    if (props.currency === 'rub') { priceLimits.symbol = '₽';}
    if (props.currency === 'usd') { priceLimits.symbol = '$';}
    if (props.currency === 'eur') { priceLimits.symbol = '€';}

    if ( sortFlightsDataProp === 'LowPrice' ) { sortItemLowPriceClass = sortItemLowPriceClass + ' filters-popup-sort-item-active'; }
    if ( sortFlightsDataProp === 'HightPrice' ) { sortItemHightPriceClass = sortItemHightPriceClass + ' filters-popup-sort-item-active'; }
    if ( sortFlightsDataProp === 'MaxPax' ) { sortItemMaxPaxClass = sortItemMaxPaxClass + ' filters-popup-sort-item-active'; }
    if ( sortFlightsDataProp === 'Year' ) { sortItemYearClass = sortItemYearClass + ' filters-popup-sort-item-active'; }

    if ( filterType === 'TurboProp' ) { filterTypeTurboPropClass = filterTypeTurboPropClass + ' filters-popup-type-turboprop-active'; }
    if ( filterType === 'MidSizeJet' ) { filterMidSizeJetClass = filterMidSizeJetClass + ' filters-popup-type-midsizejet-active'; }
    if ( filterType === 'SuperJet' ) { filterSuperJetClass = filterSuperJetClass + ' filters-popup-type-superjet-active'; }

    if ( props.currency === 'rub') { defaultCurrencyRubClass = defaultCurrencyRubClass + ' price-filter-currency-item-active'; }
    if ( props.currency === 'usd') { defaultCurrencyUsdClass = defaultCurrencyUsdClass + ' price-filter-currency-item-active'; }
    if ( props.currency === 'eur') { defaultCurrencyEurClass = defaultCurrencyEurClass + ' price-filter-currency-item-active'; }

    return (
        <div className="filters">
            <div className={ isFiltersPopupOpened? 'filters-popup' : 'filters-popup-closed' } >
                <div className="filters-popup-sort">
                    <div className={ sortItemLowPriceClass } onClick={ () => changeSortFlightsDataProp('LowPrice') } ><span><FormattedMessage id='sort_low_price' /></span></div>
                    <div className={ sortItemHightPriceClass } onClick={ () => changeSortFlightsDataProp('HightPrice') } ><span><FormattedMessage id='sort_hight_price' /></span></div>
                    <div className={ sortItemMaxPaxClass }  onClick={ () => changeSortFlightsDataProp('MaxPax') } ><span><FormattedMessage id='sort_pax' /></span></div>
                    <div className={ sortItemYearClass } onClick={ () => changeSortFlightsDataProp('Year') } ><span><FormattedMessage id='sort_year' /></span></div>
                </div>
                <div className="filters-popup-line" />
                <div className="filters-popup-price">
                    <div className="filters-popup-price-label">
                        <FormattedMessage id='price_filter' /> <FormattedNumber value={ filterPrice } /> { priceLimits.symbol }
                    </div>
                    <Slider
                        min={ priceLimits.lowestPrice }
                        max={ priceLimits.highestPrice }
                        value={ filterPrice }
                        onChange={ (value) => setFilterPrice(value) }
                    />
                    <div className="price-filter-limits">
                        <span>{ ConvertToInternationalCurrencySystem(priceLimits.lowestPrice) } { priceLimits.symbol } </span>
                        <span>{ ConvertToInternationalCurrencySystem(priceLimits.highestPrice) } { priceLimits.symbol }</span>
                    </div>
                </div>
                <div className="filters-popup-line" />
                <div className="filters-popup-type">
                    <div className="filters-popup-type-label"><FormattedMessage id="class" /></div>
                    <div className="filters-popup-type-wrapper">
                        <div className={ filterTypeTurboPropClass } onClick={ () => changeFilterType('TurboProp') }><span>Turboprop</span></div>
                        <div className={ filterMidSizeJetClass} onClick={ () => changeFilterType('MidSizeJet') }><span>Midsize Jet</span></div>
                        <div className={ filterSuperJetClass } onClick={ () => changeFilterType('SuperJet') }><span>Super Jet</span></div>
                    </div>
                </div>
                <div className="filters-popup-line" />
                <div className="price-filter-currency">
                    <div className={ defaultCurrencyRubClass } onClick={ () => changeDefaultCurrency('rub') } ><span><FormattedMessage id="currency_in_rub" /></span></div>
                    <div className={ defaultCurrencyUsdClass } onClick={ () => changeDefaultCurrency('usd') } ><span><FormattedMessage id="currency_in_usd" /></span></div>
                    <div className={ defaultCurrencyEurClass } onClick={ () => changeDefaultCurrency('eur') } ><span><FormattedMessage id="currency_in_eur" /></span></div>
                </div>
                <div className="price-filter-accept-button" onClick={ () => changeIsFiltersPopupOpened(false) } >
                    <span>{ props.flightsNumber? <FormattedMessage id='variants' values={{ count: props.flightsNumber }}/> : null } ></span>
                </div>
            </div>
            <div className="modal-window" style={{ display: isFiltersPopupOpened? 'block' : 'none' }} onClick={ () => changeIsFiltersPopupOpened(false) } />
            <div className="quantity">
                { props.flightsNumber? <FormattedMessage id='variants' values={{ count: props.flightsNumber }}/> : null}
            </div>
            <div className="filters-buttons">
                <PaxButton
                    pax = { props.pax }
                    setMorePax = { props.paxMoreChangeHandler }
                    setLessPax = { props.paxLessChangeHandler }
                />
                <FilterButton
                    label = { Intl.messages['filters'] }
                    leftIcon = { advancedPic }
                    action = { () => changeIsFiltersPopupOpened(true) }
                />
                <FilterButton
                    label = { Intl.messages['return_flight'] }
                    leftIcon = { props.departureBackDate ? returnPicActive : returnPic }
                    style = { 'return-button' }
                    action = { () => props.returnButtonHandler() }
                />
            </div>
        </div>
    );
}
