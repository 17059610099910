import { useIntl, FormattedMessage } from 'react-intl';

import './HowItWorks.scss';

import shema from '../assets/how-it-works-shema.png';
import shemaSmall from '../assets/how-it-works-shema-small.png';
import comics from '../assets/how-it-works-comics.png';
import screen from '../assets/how-it-works-screen.png';
import offer from  '../assets/how-it-works-offer.png';
import comic from '../assets/how-it-works-comic.png';

import { useState } from "react";

export default function HowItWorks () {
    const [isComicOpened, setIsComicOpened] = useState(false);

    const Intl = useIntl();
    return (
        <div className="how-it-works-content">
            { isComicOpened &&
                <div className='modal-window' onClick={() => setIsComicOpened(false) }>
                    <img src={ comic } />
                </div>
            }
            <div className="how-it-works-content-slide1">
                <div className="how-it-works-content-slide1-header">
                    <FormattedMessage id='howitworks_slide1_header' />
                    <div className="how-it-works-content-slide1-header-caption"><FormattedMessage id='howitworks_title' /></div>
                </div>
                <div className="how-it-works-content-slide1-offer">
                    <img src={ offer} />
                </div>
            </div>
            <div className="how-it-works-content-slide2">
                <div className="how-it-works-content-slide2-header">
                    <FormattedMessage id='howitworks_slide2_header' />
                    <div className="how-it-works-content-slide2-example">
                        <FormattedMessage id='howitworks_slide2_example' />
                        <div className="how-it-works-content-slide2-example-splitter" />
                    </div>
                    <div className="how-it-works-content-slide2-images">
                        <img className="shema" src={ shema } />
                        <img className="shema-small" src={ shemaSmall } />
                        <img className="comics" src={ comics  } onClick={() => setIsComicOpened(true) }/>
                    </div>
                </div>
            </div>
            <div className="how-it-works-content-slide3">
                <div className="how-it-works-content-slide3-header">
                    <FormattedMessage id='howitworks_slide3_header' />
                    <div className="how-it-works-content-slide3-header-caption"><FormattedMessage id='howitworks_slide3_caption' /></div>
                </div>
                <div className="how-it-works-slide3-screen">
                    <img src={ screen } />
                </div>
            </div>
            <div className="how-it-works-content-slide4">
                <div className="how-it-works-content-slide4-header">
                    <FormattedMessage id='howitworks_slide4_header' />
                    <div className="how-it-works-content-slide4-header-caption"><FormattedMessage id='howitworks_slide4_caption1' /></div>
                    <div className="how-it-works-content-slide4-header-caption"><FormattedMessage id='howitworks_slide4_caption2' /></div>
                    <div className="how-it-works-content-slide4-header-caption"><FormattedMessage id='howitworks_slide4_caption3' /></div>
                </div>
            </div>
        </div>
    );
}
