import './SeoSearchResults.scss';

import { useEffect, useState } from "react";

import RenderSearchResults from "./RenderSearchResults";
import isPreRendering from "./IsPreRendering";
import GetFlightsData from "./GetFlightsData";

export default function SeoSearchResults(props)  {
    const [flightsData, setFlightsData] = useState(null);

    const [isLoading, setIsLoading] = useState( true);
    const [error, setError] = useState(null);

    useEffect(() => {
        GetFlightsData( { emptyLeg : true, pax_there: 1 }, setFlightsData, null, setIsLoading, setError );
    }, [])

    return (
        <div className="seo-search-results">
            <div className="seo-search-results-header">{ props.header }</div>
            <div className="seo-search-results-caption">{ props.caption }</div>
            { !isPreRendering() &&
                <div className="flights">
                    <RenderSearchResults
                        flightsData={ flightsData }
                        airportsData={ props.airportsData }
                        isLoading={ isLoading }
                        currency={ 'eur' }
                        emptyLeg = { true }
                    />
                </div>
            }
        </div>
    );
}