import GetAirportsList from "./GetAirportsList";
import './SearchFormHandler.scss';

import { useEffect, useState } from "react";
import { useIntl } from 'react-intl';

import close_icon from "../assets/close.png";

export default function SearchFormHandler( props )  {
    const [searchText, setSearchText] = useState('')
    const Intl = useIntl();

    function handleSelectedChange(airport) {
        props.handleSelectedChange(airport);
        setSearchText('');
    }

    function handleSearchTextChange(e) {
        setSearchText( e.target.value )
    }

    useEffect( () => {
        document.getElementById('airport_enter_field').focus();
        setSearchText('');
    },[props.isSearching]);

    return (
        <form className="search_action" style={{ display: props.isSearching ? 'block' : 'none' }} >
            <div className="header">
                <span>
                    { props.isToSearching ? Intl.messages['to'] : Intl.messages['from'] }
                </span>
                <span>
                    <a onClick={ () => props.handleIsSearchingChange() }>
                        <div className="mobile_close">
                            <img src={ close_icon }  alt={ Intl.messages['close'] } />
                        </div>
                    </a>
                </span>
            </div>
            <input
                type="text"
                id="airport_enter_field"
                placeholder={ Intl.messages['enter_airport_or_city'] }
                className="input"
                value= { searchText }
                onChange={ handleSearchTextChange }
            />
            <GetAirportsList
                searchText = { searchText }
                handleSelectedChange = { handleSelectedChange }
                airportsData = { props.aiportsData }
            />
        </form>
    );
}
