export default function GetAiportById (airportICAO, airportsData, locale)  {
    let airportData;
    for (let i=0;i<airportsData.length;i++) {
        if (airportsData[i].icao === airportICAO) {
            airportData = Object.assign({},airportsData[i]);
            if (locale === 'ru-RU') {
                airportData.name = airportsData[i].name_rus;
                airportData.city = airportsData[i].city_rus;
            }
        }
    }
    return airportData;
}