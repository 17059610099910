import './SeoBBlock.scss';

import seoBBImage from '../assets/seo-bb-image.png';

export default function SeoBBlock(props)  {
    return (
        <div className="seo-bblock">
            <div className="seo-bblock-pictures">
                <img src={ seoBBImage } alt={ props.header } />
            </div>
            <div className="seo-bblock-content">
                <div className="seo-bblock-header">{ props.header }</div>
                <div className="seo-bblock-caption">{ props.caption }</div>
                <div className="seo-bblock-messages">
                    <ul>
                        <li>{ props.message1 }</li>
                        <li>{ props.message2 }</li>
                        <li>{ props.message3 }</li>
                        <li>{ props.message4 }</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}