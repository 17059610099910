import './App.scss';

import { YMInitializer } from 'react-yandex-metrika';
import ym from 'react-yandex-metrika';

import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { IntlProvider } from "react-intl";
import { Fragment, useEffect } from "react";

import { LOCALES } from "./intl/locales";
import { messages } from "./intl/messages";

import Home from "./pages/Home";
import Search from "./pages/Search";
import StaticPage from "./pages/StaticPage";
import Fleet from "./pages/Fleet";
import Seo from "./pages/Seo";

import { ARENDA_SAMOLETA } from "./intl/seo/arenda_chastnogo_samoleta";
import { ZAKAZ_CHARTERA } from "./intl/seo/zakaz-chartera";
import { AVIABROKER } from "./intl/seo/aviabroker";
import { ARENDA_BIZNES_DZHETA } from "./intl/seo/arenda-biznes-dzheta";
import { CHASTNY_PERELET } from "./intl/seo/chasny_perelet";
import { CHASTNYI_REJS } from "./intl/seo/chastnyj_rejs";
import { DELOVAJA_BIZNES_AVIACIJA } from "./intl/seo/delovaja_biznes_aviacia";
import { VIP_CHARTER } from "./intl/seo/vip-charter";
import { PRIVATE_JET } from "./intl/seo/private-jet";
import { ZAKAZ_SAMOLETA } from "./intl/seo/zakaz-samoleta";

import isPreRendering from "./components/IsPreRendering";

export default function App() {
    const { pathname, hash, key } = useLocation();

    useEffect(() => {
        ym('hit', pathname);
        // if not a hash link, scroll to top
        if (hash === '') {
            window.scrollTo(0, 0);
        }
        // else scroll to id
        else {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView();
                }
            }, 0);
        }
    }, [pathname, hash, key]);

    return (
        <Fragment>
            <YMInitializer accounts={[61537396]} options={{
                defer: true,
                clickmap:true,
                trackLinks:true,
                accurateTrackBounce:true,
                webvisor:true
            }} version="2" />
            <Routes>
                <Route path='/en/' element={
                    <IntlProvider locale={ LOCALES.ENGLISH } messages={ messages[ LOCALES.ENGLISH ] }>
                        <Home />
                    </IntlProvider>
                } />
                <Route path='/' element={
                    <IntlProvider locale={ LOCALES.RUSSIAN } messages={ messages[ LOCALES.RUSSIAN ] }>
                        <Home />
                    </IntlProvider>
                } />
                <Route path='/search/:fromAirport/:toAirport/:date/:passengers' element={
                    <IntlProvider locale={ LOCALES.RUSSIAN } messages={ messages[ LOCALES.RUSSIAN ] }>
                        <Search />
                    </IntlProvider>
                } />
                <Route path='/en/search/:fromAirport/:toAirport/:date/:passengers' element={
                    <IntlProvider locale={ LOCALES.ENGLISH } messages={ messages[ LOCALES.ENGLISH ] }>
                        <Search  />
                    </IntlProvider>
                } />
                <Route path='/search/:requestId' element={
                    <IntlProvider locale={ LOCALES.RUSSIAN } messages={ messages[ LOCALES.RUSSIAN ] }>
                        <Search />
                    </IntlProvider>
                } />
                <Route path='/en/search/:requestId' element={
                    <IntlProvider locale={ LOCALES.ENGLISH } messages={ messages[ LOCALES.ENGLISH ] }>
                        <Search  />
                    </IntlProvider>
                } />
                <Route path='/flight/:flightId' element={
                    <IntlProvider locale={ LOCALES.RUSSIAN } messages={ messages[ LOCALES.RUSSIAN ] }>
                        <Search  />
                    </IntlProvider>
                } />
                <Route path='/en/flight/:flightId' element={
                    <IntlProvider locale={ LOCALES.ENGLISH } messages={ messages[ LOCALES.ENGLISH ] }>
                        <Search  />
                    </IntlProvider>
                } />
                <Route path='/el/' element={
                    <IntlProvider locale={ LOCALES.RUSSIAN } messages={ messages[ LOCALES.RUSSIAN ] }>
                        { !isPreRendering() &&
                            <Search
                                emptyLeg={ true }
                            />
                        }
                    </IntlProvider>
                } />
                <Route path='/en/el/' element={
                    <IntlProvider locale={ LOCALES.ENGLISH } messages={ messages[ LOCALES.ENGLISH ] }>
                        { !isPreRendering() &&
                            <Search
                                emptyLeg={ true }
                            />
                        }
                    </IntlProvider>
                } />
                <Route path='/en/contacts/' element={
                    <IntlProvider locale={ LOCALES.ENGLISH } messages={ messages[ LOCALES.ENGLISH ] }>
                        <StaticPage  page={'contacts'} />
                    </IntlProvider>
                } />
                <Route path='/contacts/' element={
                    <IntlProvider locale={ LOCALES.RUSSIAN } messages={ messages[ LOCALES.RUSSIAN ] }>
                        <StaticPage  page={'contacts'} />
                    </IntlProvider>
                } />
                <Route path='/en/fleet/' element={
                    <IntlProvider locale={ LOCALES.ENGLISH } messages={ messages[ LOCALES.ENGLISH ] }>
                        <Fleet  />
                    </IntlProvider>
                } />
                <Route path='/fleet/' element={
                    <IntlProvider locale={ LOCALES.RUSSIAN } messages={ messages[ LOCALES.RUSSIAN ] }>
                        <Fleet  />
                    </IntlProvider>
                } />
                <Route path='/en/restrictions/' element={
                    <IntlProvider locale={ LOCALES.ENGLISH } messages={ messages[ LOCALES.ENGLISH ] }>
                        <StaticPage
                            page={'restrictions'}
                        />
                    </IntlProvider>
                } />
                <Route path='/restrictions/' element={
                    <IntlProvider locale={ LOCALES.RUSSIAN } messages={ messages[ LOCALES.RUSSIAN ] }>
                        <StaticPage
                            page={'restrictions'}
                        />
                    </IntlProvider>
                } />
                <Route path='/en/about/' element={
                    <IntlProvider locale={ LOCALES.ENGLISH } messages={ messages[ LOCALES.ENGLISH ] }>
                        <StaticPage
                            page={'about'}
                        />
                    </IntlProvider>
                } />
                <Route path='/about/' element={
                    <IntlProvider locale={ LOCALES.RUSSIAN } messages={ messages[ LOCALES.RUSSIAN ] }>
                        <StaticPage
                            page={'about'}
                        />
                    </IntlProvider>
                } />
                <Route path='/en/partners/' element={
                    <IntlProvider locale={ LOCALES.ENGLISH } messages={ messages[ LOCALES.ENGLISH ] }>
                        <StaticPage
                            page={'partners'}
                        />
                    </IntlProvider>
                } />
                <Route path='/partners/' element={
                    <IntlProvider locale={ LOCALES.RUSSIAN } messages={ messages[ LOCALES.RUSSIAN ] }>
                        <StaticPage
                            page={'partners'}
                        />
                    </IntlProvider>
                } />
                <Route path='/en/howitworks/' element={
                    <IntlProvider locale={ LOCALES.ENGLISH } messages={ messages[ LOCALES.ENGLISH ] }>
                        <StaticPage
                            page={'howitworks'}
                        />
                    </IntlProvider>
                } />
                <Route path='/howitworks/' element={
                    <IntlProvider locale={ LOCALES.RUSSIAN } messages={ messages[ LOCALES.RUSSIAN ] }>
                        <StaticPage
                            page={'howitworks'}
                        />
                    </IntlProvider>
                } />
                <Route path='/en/404/' element={
                    <IntlProvider locale={ LOCALES.ENGLISH } messages={ messages[ LOCALES.ENGLISH ] }>
                        <StaticPage
                            page={'not_found'}
                        />
                    </IntlProvider>
                } />
                <Route path='/404/' element={
                    <IntlProvider locale={ LOCALES.RUSSIAN } messages={ messages[ LOCALES.RUSSIAN ] }>
                        <StaticPage
                            page={'not_found'}
                        />
                    </IntlProvider>
                } />
                <Route path='/arenda-chastnogo-samoleta/' element={
                    <IntlProvider locale={ LOCALES.RUSSIAN } messages={ messages[ LOCALES.RUSSIAN ] }>
                        <Seo
                            texts = { ARENDA_SAMOLETA }
                        />
                    </IntlProvider>
                } />
                <Route path='/arenda-biznes-dzheta/' element={
                    <IntlProvider locale={ LOCALES.RUSSIAN } messages={ messages[ LOCALES.RUSSIAN ] }>
                        <Seo
                            texts = { ARENDA_BIZNES_DZHETA }
                        />
                    </IntlProvider>
                } />
                <Route path='/zakaz-chartera/' element={
                    <IntlProvider locale={ LOCALES.RUSSIAN } messages={ messages[ LOCALES.RUSSIAN ] }>
                        <Seo
                            texts = { ZAKAZ_CHARTERA }
                        />
                    </IntlProvider>
                } />
                <Route path='/chasny-perelet/' element={
                    <IntlProvider locale={ LOCALES.RUSSIAN } messages={ messages[ LOCALES.RUSSIAN ] }>
                        <Seo
                            texts = { CHASTNY_PERELET }
                        />
                    </IntlProvider>
                } />
                <Route path='/delovaja-biznes-aviacia/' element={
                    <IntlProvider locale={ LOCALES.RUSSIAN } messages={ messages[ LOCALES.RUSSIAN ] }>
                        <Seo
                            texts = { DELOVAJA_BIZNES_AVIACIJA }
                        />
                    </IntlProvider>
                } />
                <Route path='/aviabroker/' element={
                    <IntlProvider locale={ LOCALES.RUSSIAN } messages={ messages[ LOCALES.RUSSIAN ] }>
                        <Seo
                            texts = { AVIABROKER }
                        />
                    </IntlProvider>
                } />
                <Route path='/vip-charter/' element={
                    <IntlProvider locale={ LOCALES.RUSSIAN } messages={ messages[ LOCALES.RUSSIAN ] }>
                        <Seo
                            texts = { VIP_CHARTER }
                        />
                    </IntlProvider>
                } />
                <Route path='/chastnyj-rejs/' element={
                    <IntlProvider locale={ LOCALES.RUSSIAN } messages={ messages[ LOCALES.RUSSIAN ] }>
                        <Seo
                            texts = { CHASTNYI_REJS }
                        />
                    </IntlProvider>
                } />
                <Route path='/private-jet/' element={
                    <IntlProvider locale={ LOCALES.RUSSIAN } messages={ messages[ LOCALES.RUSSIAN ] }>
                        <Seo
                            texts = { PRIVATE_JET }
                        />
                    </IntlProvider>
                } />
                <Route path='/zakaz-samoleta/' element={
                    <IntlProvider locale={ LOCALES.RUSSIAN } messages={ messages[ LOCALES.RUSSIAN ] }>
                        <Seo
                            texts = { ZAKAZ_SAMOLETA }
                        />
                    </IntlProvider>
                } />
                <Route path='/en/*' status={404} element={
                    <IntlProvider locale={ LOCALES.ENGLISH } messages={ messages[ LOCALES.ENGLISH ] }>
                        <StaticPage
                            page={'not_found'}
                        />
                    </IntlProvider>
                } />
                <Route path='*'  status={404} element={
                    <IntlProvider locale={ LOCALES.RUSSIAN } messages={ messages[ LOCALES.RUSSIAN ] }>
                        <StaticPage
                            page={'not_found'}
                        />
                    </IntlProvider>
                } />
            </Routes>
        </Fragment>
    );
}
