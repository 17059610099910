export const DELOVAJA_BIZNES_AVIACIJA = {
    title : 'Деловая бизнес авиация. Мгновенное предложение онлайн. NAJET.',
    description : 'NAJET это удобный онлайн сервис подбора и бронирования рейсов деловой и бизнес авиации. Мы предоставляем отличный клиентский сервис и широкий выбор ВС по минимальным ценам.',
    keywords: 'деловая авиация, бизнес авиация',

    header : 'Онлайн-сервис подбора рейсов деловой бизнес авиации',

    bblock_header : 'Какие задачи решает деловая бизнес авиация?',
    bblock_caption : 'В середине прошлого века многие политики, а также люди, которые крутятся в деловой сфере в западных странах, стали летать на деловые встречи на самолетах. Это и привело к появлению такой услуги, как – деловая (бизнес) авиация.',
    bblock_message1 : 'Самые первые воздушные суда стоили достаточно дорого. Именно этот факт и послужил тому, что деловая авиация стала основной привилегией очень влиятельных людей.',
    bblock_message2 : 'Многие известные люди летали на встречи на корпоративных, а позже – и на личных джетах. Их основной целью было - произвести особенное впечатление на своих партнеров по бизнесу. Это сработало очень быстро.',
    bblock_message3 : 'Маневренный персональный самолет в элитных кругах стал символом очень высокого социального положения человека, а также его влиятельности. При помощи них стало очень легко и удобно добираться в любую точку мира.',
    bblock_message4 : 'Перелеты на бизнес джетах стали более доступными. Для того, чтобы воспользоваться деловым джетом, теперь не обязательно его покупать, достаточно взять его просто в аренду на необходимый период.',

    faq_header : 'Каковы основные достоинства услуги деловой бизнес авиации?',
    faq_message1 : 'Свобода путешествий. Вы сами определяете время и маршрут полета бизнес-джета («частного самолета»).',
    faq_message2 : 'Оперативность и точность. Вылет состоится точно в оговоренное время, независимо от дня недели и времени суток.',
    faq_message3 : 'Подбор самолета под ваши запросы. Заказчик получает предложения на все бизнес джеты, которые могут выполнить заявленный перелет.',
    faq_message4 : 'Высокий уровень безопасности. В деловой авиации работают высококвалифицированные пилоты,которым подается полностью исправная техника.',
    faq_message5 : 'Абсолютная приватность. Все перелеты на деловых джетах проходят абсолютно конфиденциально.',
    faq_message6 : 'Повышенный комфорт. Салоны бизнес джетов делают полет  максимально комфортным, клиентам предлагается обслуживание VIP уровня.',
    faq_caption : ' Вместе с тем все пассажиры также проходят контроль багажа, что исключает риски террористических атак. Даже в аэропорту деловые люди находятся в безопасности, поскольку для них работают отдельные стойки и залы обслуживания.',

    spec_items_intl_header : 'Международные перелеты на арендованном самолете',
    spec_items_intl_destination1 : 'Москва — Минск',
    spec_items_intl_price1 : 'от 15 000 €',
    spec_items_intl_link1: 'search/UUWW/UMMS',
    spec_items_intl_destination2 : 'Москва — Баку',
    spec_items_intl_price2 : 'от 55 000 €',
    spec_items_intl_link2: 'search/UUWW/UBBB',
    spec_items_intl_destination3 : 'Москва — Нур-Султан',
    spec_items_intl_price3 : 'от 50 000 €',
    spec_items_intl_link3: 'search/UUWW/UACC',
    spec_items_intl_destination4 : 'Москва — Стамбул',
    spec_items_intl_price4 : 'от 70 000 €',
    spec_items_intl_link4: 'search/UUWW/LTFM',
    spec_items_intl_action : 'Смотреть предложения',

    spec_items_dom_header : 'Региональные перелеты на арендованном бизнес джете',
    spec_items_dom_destination1 : 'Москва — Сочи',
    spec_items_dom_price1 : 'от 55 000 €',
    spec_items_dom_link1: 'search/UUWW/URSS',
    spec_items_dom_destination2 : 'Москва — Санкт-Петербург',
    spec_items_dom_price2 : 'от 14 000 €',
    spec_items_dom_link2: 'search/UUWW/ULLI',
    spec_items_dom_destination3 : 'Москва — Казань',
    spec_items_dom_price3 : 'от 17 000 €',
    spec_items_dom_link3: 'search/UUWW/UWKD',
    spec_items_dom_destination4 : 'Москва — Тюмень',
    spec_items_dom_price4 : 'от 40 000 €',
    spec_items_dom_link4: 'search/UUWW/USTR',
    spec_items_dom_action : 'Смотреть предложения',

    search_results_header : 'Выгодные предложения',
    search_results_caption : 'Особо выгодное предложение от авиакомпаний, которые совершают пустой перелет по вашему маршруту:',

    jet_header : 'Заказ рейса деловой бизнес Авиации',
    jet_caption : 'Сервис NAJET это самый быстрый способ узнать доступность  и точную стоимость аренды самолета деловой авиации. Поисковик на сайте сервиса NAJET работает в режиме онлайн. То есть вы получите необходимую информацию о возможных для аренды самолета в течение секунд без всяких дополнительных регистраций.',

    footer_left_text: 'Главной задачей NAJET было – организовать весь процесс заказа воздушного чартера деловой авиации легко, быстро и в течение нескольких минут. При этом, чтобы клиент не тратил свое время, силы и нервы, а мог получить утвердительный ответ и точную стоимость услуги в самое ближайшее время. После того, как клиент оставляет заявку на Платформе NAJET, он получает всю информацию о свободных самолетах для бизнес авиации, экипаже и другие важные вопросы в режиме онлайн.',
    footer_right_text: 'Наша система устроена таким образом, что информация собирается сразу из всех Авиакомпаний, которые сотрудничают с нами и вносится в базу. Таким образом, клиент получает очень быстрый ответ предложения обо всех свободных самолетах, которые могут осуществить его заказ. Клиент может легко ознакомиться со всеми предложениями и выбрать наиболее подходящий вариант. Также через интернет он имеет возможность переслать полученные данные своему руководителю или партнеру, забронировать рейс и оплатить его. Все, что ему остается – это прибыть в назначенное время в аэропорт.',
}

