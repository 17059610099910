import './Menu.scss';

import { useIntl, FormattedMessage } from 'react-intl';
import { Link } from "react-router-dom";

export default function Menu (props)  {

    const Intl = useIntl();

    return (
        <div className="header-menu" onClick={ () => props.changeIsMenuOpened(false) } >
            <div className="header-menu-item"><Link to=""><FormattedMessage id='main' /></Link></div>
            <div className="header-menu-item"><Link to=""><FormattedMessage id='fleet_link_text' /></Link></div>
            <div className="header-menu-item"><a href=""><FormattedMessage id='partners' /></a></div>
            <div className="header-menu-item"><Link to=""><FormattedMessage id='about' /></Link></div>
            <div className="header-menu-item"><Link to=""><FormattedMessage id='contacts' /></Link></div>
            <div className="header-menu-subitem"><Link to=""><FormattedMessage id='how_it_works' /></Link></div>
        </div>
    );
}
