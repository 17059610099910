// Считает либо среднее время перелета по поисковой выдаче, либо среднее время для конкретного предложения

function getDateDiff( props ) {
    let departureDate = new Date( props.departureDate );
    let arrivalDate = new Date( props.arrivalDate );
    return parseInt( arrivalDate - departureDate );
}

function convertDateDiff( diff ) {
    let offSet = new Date().getTimezoneOffset()*60*1000;
    return new Date(diff + offSet);
}

export default function GetAverageFlightTime ( props )  {
    let flightsData = props.flightsData;
    if ( props.flightInfo ) {
        flightsData = { cards : [ props.flightInfo ] };
    }
    if ( flightsData?.cards.length ){
        let sumTimeThere = 0;
        let sumTimeBack = 0;
        for (let i=0;i<flightsData.cards.length;i++) {
            sumTimeThere = sumTimeThere + getDateDiff({
                departureDate: flightsData.cards[i].routes[0].departure_date,
                arrivalDate: flightsData.cards[i].routes[0].arrival_date
            });
            // Учитываем обратное направление
            if (flightsData.cards[i].routes[1]) {
                sumTimeBack = sumTimeBack + getDateDiff({
                    departureDate: flightsData.cards[i].routes[1].departure_date,
                    arrivalDate: flightsData.cards[i].routes[1].arrival_date
                });
            }
        }
        let averageThere = sumTimeThere / flightsData.cards.length;
        let averageBack = sumTimeBack / flightsData.cards.length;
        return {
            there: convertDateDiff(averageThere),
            back: convertDateDiff(averageBack)
        };
    }
    // Обрабатываем вариант, когда нужно посчитать полетное время по дате вылета и прилета
    if (props.departureDate && props.arrivalDate) {
        return convertDateDiff(getDateDiff({
            departureDate: props.departureDate,
            arrivalDate: props.arrivalDate
        }));
    }
}